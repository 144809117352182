import styled from 'styled-components';

import { FlexRow } from '@/components/designSystem/Layout';

export const StyledButtonWrapper = styled.div`
  margin-top: 1rem;
`;

export const StyledCheckboxWrapper = styled(FlexRow)`
  background-color: white;
  padding: 1rem;
  margin-bottom: 0.5rem;
`;

export const StyledFlexRowWrapperWithGap = styled(FlexRow)`
  flex-grow: 1;
  gap: 3rem;
`;

export const StyledAdditionalActionsButtonContainer = styled.div`
  width: 20rem;
`