import React from 'react';

import { Item } from './Item';
import { StyledLink } from './styled';

interface IProps {
  title: string,
  onClick?: () => void,
  toggleMenu?: () => void,
}

export const LinkItem = ({ title, onClick, toggleMenu }: IProps) => {
  const handleClick = () => {
    if (toggleMenu && typeof toggleMenu === 'function') {
      toggleMenu();
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <StyledLink onClick={handleClick}>
      <Item title={title} />
    </StyledLink>
  );
};
