import React, { Dispatch, useContext, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro-spa';

import { useMutation } from '@apollo/react-hooks';
import { ANSWER_CUSTOM_FIELDS } from '@/graphql/mutations/answerCustomFields';

import { Formik } from 'formik';

import { noop } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';
import { mapFormValuesToFieldAnswers } from '@/utils/customFields';

import { ICustomField } from '@/types/customField';
import { ISettings } from '@/types/settings';

import { VisitorContext } from '@/providers/VisitorContext';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { OnboardValuesContext } from '../../context';
import { otherInfoStepValidation } from './validation';
import { OtherInfoStepForm } from './form';
import { IOnboardValuesAction } from '../../types';
import { UPDATE_OTHER_INFO } from '../../reducer';
import { IOtherInfoValues } from './types';
import { mapFormValuesToApplicationAttrs } from './functions';

interface IProps {
  dispatch: Dispatch<IOnboardValuesAction>,
  companySettings: ISettings,
  customFields: ICustomField[],
  showCreditTerms: boolean,
  creditTermsOptions: string[],
  requireEinFederalTaxNumber: boolean,
}

export const OtherInfoStep = ({
  dispatch,
  customFields,
  showCreditTerms,
  creditTermsOptions,
  requireEinFederalTaxNumber,
}: IProps) => {
  const visitorData: ExtendedGetResult | null = useContext(VisitorContext);
  const history = useHistory();
  const { search } = useLocation();

  const onboardValues = useContext(OnboardValuesContext);
  const firstUpdate = useRef(true);

  const [answerCustomFields] = useMutation(ANSWER_CUSTOM_FIELDS);

  const submitStep = () => {
    if (!onboardValues) {
      return noop;
    }

    const customFieldAnswers = mapFormValuesToFieldAnswers(onboardValues.otherInfo.customFields, customFields);
    const applicationAttrs = mapFormValuesToApplicationAttrs(onboardValues.otherInfo);

    return answerCustomFields({
      variables: {
        customerId: onboardValues.customerId,
        customFieldAnswers,
        applicationAttrs,
        visitorData
      }
    })
      .then(() => {
        history.push({ pathname: `${onboardValues.basePath}/agreement`, search });
      })
      .catch(() => {
        showToast({
          title: 'Error',
          description: 'Something went wrong',
          type: toast.TYPE.ERROR,
        });
      });
  }

  useEffect(() => {
    // To skip function call after initial render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    submitStep();
  }, [onboardValues]);

  const dispatchValues = (values: IOtherInfoValues) => {
    dispatch({ type: UPDATE_OTHER_INFO, payload: values })
  }

  if (!onboardValues) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={onboardValues.otherInfo}
      validationSchema={otherInfoStepValidation(customFields, requireEinFederalTaxNumber)}
      onSubmit={(values) => handleSubmitWrapper(values, dispatchValues)}
    >
      <OtherInfoStepForm
        customFields={customFields}
        creditTermsOptions={creditTermsOptions}
        showCreditTerms={showCreditTerms}
        requireEinFederalTaxNumber={requireEinFederalTaxNumber}
      />
    </Formik>
  )
};
