import styled from 'styled-components';

interface IFlex {
  direction?: 'column' | 'row',
  justify?: 'space-between' | 'space-evenly' | 'center' | 'start' | 'end' | 'flex-start' | 'flex-end',
  align?: string, // 'normal' | 'stretch' | 'center' | 'start' | 'end' | 'flex-start' | 'flex-end'
  border?: string,
  padding?: string,
  margin?: string,
  gap?: string
}

export const Flex = styled.div<IFlex>`
  display: flex;
  flex-direction: ${ props => props.direction || 'row' };
  padding: ${ props => props.padding || '0' };
  margin: ${ props => props.margin || '0' };
  justify-content: ${ props => props.justify || 'start' };
  align-items: ${ props => props.align || 'normal' };
  border: ${ props => props.border || 'none'};
  gap: ${ props => props.gap || '0' }
`;

export const FlexRow = styled(Flex)`
  flex-direction: row;
`;

export const FlexRowSpaceBetween = styled(FlexRow)`
  justify-content: space-between;
`;

export const FlexRowSpaceEvenly = styled(FlexRow)`
  justify-content: space-evenly;
`;

export const FlexRowSpaceBetweenItemsCenter = styled(FlexRowSpaceBetween)`
  align-items: center;
`;

export const FlexRowStart = styled(FlexRow)`
  justify-content: start;
`;

export const FlexRowEnd = styled(FlexRow)`
  justify-content: end;
`;

export const FlexRowEndItemsCenter = styled(FlexRowEnd)`
  align-items: center;
`;

export const FlexRowCenter = styled(FlexRow)`
  justify-content: center;
`;

export const FlexRowCenterItemsCenter = styled(FlexRowCenter)`
  align-items: center;
`;

export const FlexRowItemsCenter = styled(FlexRow)`
  align-items: center;
`;

export const FlexRowStartItemsCenter = styled(FlexRowStart)`
  align-items: center;
`;

export const FlexRowItemsEnd = styled(FlexRow)`
  align-items: flex-end;
`;

export const FlexColumn = styled(Flex)`
  flex-direction: column;
`;

export const FlexColumnSpaceBetween = styled(FlexColumn)`
  justify-content: space-between;
`;

export const FlexColumnSpaceBetweenItemsCenter = styled(FlexColumnSpaceBetween)`
  align-items: center;
`;

export const FlexColumnCenter = styled(FlexColumn)`
  justify-content: center;
`;

export const FlexColumnEnd = styled(FlexColumn)`
  justify-content: flex-end;
`;

export const FlexColumnItemsStart = styled(FlexColumn)`
  align-items: flex-start;
`;

export const FlexColumnItemsEnd = styled(FlexColumn)`
  align-items: flex-end;
`;

export const FlexColumnItemsCenter = styled(FlexColumn)`
  align-items: center;
`;

export const FlexColumnCenterItemsCenter = styled(FlexColumnCenter)`
  align-items: center;
`;

export const FlexColumnCenterItemsEnd = styled(FlexColumnCenter)`
  align-items: flex-end;
`;

export const FlexColumnItemsStretch = styled(FlexColumn)`
  align-items: stretch;
`;

export const FlexColumnGap = styled(FlexColumn)`
  gap: 0.5rem;
`;
