import React from 'react';
import { useLocation } from 'react-router';

import { IBrand } from '@/types/publicCompany';

import { BackButton } from '@/components/designSystem/buttons/BackButton';

import { ReactComponent as PoweredByLogo } from '@/assets/powered_by.svg';

import { StepHeader } from '../StepHeader';
import {
  StyledFormContainer,
  StyledLeftBlock,
  StyledMain,
  StyledRightBlock,
  StyledPoweredByContainer,
} from './styled';
import { STEPS } from './constants';

interface IProps {
  children: React.ReactNode,
  overriddenLogo: JSX.Element | null,
  brand: IBrand | undefined
  index: number,
  prevPath: string | null,
}

export const StepWrapper = ({ overriddenLogo, brand, children, index, prevPath }: IProps) => {
  const { search } = useLocation();

  return (
    <>
      <StepHeader overriddenLogo={overriddenLogo} index={index} steps={STEPS} minutes={2} />
      <StyledMain>
        {prevPath ? (
          <StyledLeftBlock>
            <BackButton link={`${prevPath}${search}`} />
          </StyledLeftBlock>
        ) : false }
        <StyledFormContainer>
          {children}
        </StyledFormContainer>
        <StyledRightBlock>
          {(brand) ? (
            <StyledPoweredByContainer href='https://nectarinecredit.com' target='_blank'>
              <PoweredByLogo />
            </StyledPoweredByContainer>
          ) : false }
        </StyledRightBlock>
      </StyledMain>
    </>
  )}
;
