import React from 'react';

import { StepNode } from './StepNode';
import { StyledStepCounterContainer, StyledTitle } from './styled';

interface IProps {
  steps: string[],
  activeStep: number,
  activeColor?: string,
}

export const StepCounter = ({ steps, activeStep, activeColor }: IProps) => (steps.length > 1
  ? (
    <StyledStepCounterContainer>
      {
        steps.map((step, index) => <StepNode key={step} title={step} index={index} activeStep={activeStep} activeColor={activeColor} />)
      }
    </StyledStepCounterContainer>
  )
  : <StyledStepCounterContainer><StyledTitle bold>{steps[0]}</StyledTitle></StyledStepCounterContainer>
);
