import { useState, useEffect } from 'react';

import { INavOptions } from '@/types/navOptions';

import { NAV_ITEMS, ROLES } from '@/constants';

export const useNavOptions = (roles: string[], options: INavOptions) => {
  const [navOptions, setNavOptions] = useState(options);

  useEffect(() => {
    let filteredOptions = [...options];
    if (roles.length) {
      if (!roles.includes(ROLES.CP)) {
        filteredOptions = [
          filteredOptions[0].filter((option) => option.title !== NAV_ITEMS.CUSTOMERS),
          filteredOptions[1].filter((option) => option.title !== NAV_ITEMS.TEMPLATE),
        ];
      }
      if (!roles.includes(ROLES.CS)) {
        filteredOptions = [
          filteredOptions[0].filter((option) => option.title !== NAV_ITEMS.APPLICATIONS),
          filteredOptions[1],
        ];
      }
      if (!roles.includes(ROLES.VENDOR)) {
        filteredOptions = [
          filteredOptions[0].filter((option) => option.title !== NAV_ITEMS.REFERENCES),
          filteredOptions[1],
        ];
      }
    }
    setNavOptions(filteredOptions);
  }, [roles]);

  return navOptions;
};
