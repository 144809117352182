import React, { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { PROVIDER_UPDATE_APPLICATION } from '@/graphql/mutations/providerUpdateApplication';

import { Formik } from 'formik';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { includes } from 'lodash';
import { get } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';
import { extractGQLErrorMessage } from '@/utils/extractGQLErrorMessage';

import { IApplication } from '@/types/application';
import { ICustomer } from '@/types/customer';

import { CREDIT_STATUSES } from '@/constants';

import { BodyText } from '@/components/designSystem/Typography';

import { SideTitle } from '../SideTitle';
import { useFields } from './useFields';
import { ActionsForm } from './ActionsForm';
import { ConfirmationModal } from './ConfirmationModal';
import { StyledSideButton, StyledApprovedContainer } from '../styled';

interface IFields {
  creditLimit: string,
  creditTerms: string,
  status: string,
  seekerDenialAlertEmail: boolean,
  seekerApprovalAlertEmail: boolean,
  providerComment: string,
}

interface IProps {
  customerData: ICustomer,
  application: IApplication,
  seekerDenialAlert: boolean,
  seekerApprovalAlert: boolean,
  creditTermsOptions: string[],
  refetchCustomerData: () => void,
}

export const Actions = ({
  customerData,
  application,
  creditTermsOptions,
  seekerDenialAlert,
  seekerApprovalAlert,
  refetchCustomerData,
}: IProps) => {
  const [updateApplication, { loading }] = useMutation(PROVIDER_UPDATE_APPLICATION);

  const applicationId = get('id', application);
  const applicationStatus = get('status', application);
  const [selectedStatus, setSelectedStatus] = useState(applicationStatus);
  const requireLimits = selectedStatus === CREDIT_STATUSES.APPROVED;

  const { fields, validation } = useFields(application, requireLimits, seekerDenialAlert, seekerApprovalAlert);

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isCreditOptionsUpdate, setCreditOptionsUpdate] = useState(false);

  const onSubmit = async ({
    creditLimit, creditTerms, status, providerComment, seekerDenialAlertEmail, seekerApprovalAlertEmail
  }: IFields) => {
    const isDenied = status === CREDIT_STATUSES.DENIED;
    const variables = {
      applicationId,
      applicationAttrs: {
        creditLimit: isDenied ? null : parseInt(creditLimit, 10),
        creditTerms: isDenied ? null : creditTerms,
        status,
        providerComment,
        seekerDenialAlertEmail,
        seekerApprovalAlertEmail,
      },
    };

    updateApplication({ variables })
      .then(() => {
        refetchCustomerData();
      })
      .catch((error) => {
        showToast({
          title: 'Error!',
          description: extractGQLErrorMessage(error),
          type: toast.TYPE.ERROR,
        });
      });

    if (isCreditOptionsUpdate) {
      setCreditOptionsUpdate(false);
    }
  };

  const submitOrConfirm = (values: any) => {
    const toConfirm = values.providerComment
      && ((includes([CREDIT_STATUSES.APPROVED], values.status) && values.seekerApprovalAlertEmail)
        || (includes([CREDIT_STATUSES.DENIED], values.status) && values.seekerDenialAlertEmail)
        || includes([CREDIT_STATUSES.CHANGES_REQUESTED], values.status));
    if (toConfirm) {
      setConfirmationModalOpen(true);
    } else {
      onSubmit(values);
    }
  };

  return (
    <div>
      <SideTitle title='Actions' />
      {(applicationStatus !== CREDIT_STATUSES.APPROVED || isCreditOptionsUpdate) ?
        <Formik
          onSubmit={(values) => handleSubmitWrapper(values, submitOrConfirm)}
          initialValues={fields}
          validationSchema={validation}
          validateOnChange
          enableReinitialize
        >
          {({ values }) => (
            <>
              <ActionsForm
                customerData={customerData}
                loading={loading}
                creditTermsOptions={creditTermsOptions}
                requireLimits={requireLimits}
                seekerDenialAlert={seekerDenialAlert}
                seekerApprovalAlert={seekerApprovalAlert}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
              />
              <ConfirmationModal
                isOpen={isConfirmationModalOpen}
                setIsOpen={setConfirmationModalOpen}
                currentStatus={applicationStatus}
                selectedStatus={selectedStatus}
                onConfirm={() => {
                  onSubmit(values);
                  setConfirmationModalOpen(false);
                  return;
                }}
              />
            </>
          )}
        </Formik>
        :
        <StyledApprovedContainer>
          <BodyText bold>Status: <BodyText>{applicationStatus}</BodyText></BodyText>
          <BodyText bold>Credit Terms: <BodyText>{application.creditTerms}</BodyText></BodyText>
          <BodyText bold>Credit Limit: <BodyText>${application.creditLimit}</BodyText></BodyText>
          { application.requestedCreditLimit ? (
            <BodyText bold>Requested Credit Limit: <BodyText>${application.requestedCreditLimit}</BodyText></BodyText>
          ) : false }
          <BodyText bold>Date approved: <BodyText>{application.dateApproved}</BodyText></BodyText>
          { application.providerComment ? (
            <BodyText bold>Comment: <BodyText>{application.providerComment}</BodyText></BodyText>
          ) : false }
          <StyledSideButton onClick={() => setCreditOptionsUpdate(true)} primary>Update Credit</StyledSideButton>
        </StyledApprovedContainer>
      }
    </div>
  )};
