import gql from 'graphql-tag';

export const GET_REFERENCE_EMAIL_ACTIVITY = gql`
  query GetReferenceEmailActivity($email: String!, $providerCompanyId: ID, $seekerCompanyId: ID) {
    getReferenceEmailActivity(email: $email, providerCompanyId: $providerCompanyId, seekerCompanyId: $seekerCompanyId){
      occuredAt,
      sentTo,
      sentBy,
      status
    }
  }
`;

export const GET_REMINDER_DETAILS = gql`
  query GetReminderDetails($email: String!, $providerCompanyId: ID) {
    getReminderDetails(email: $email, providerCompanyId: $providerCompanyId){
      numSent,
      lastSent
    }
  }
`;
