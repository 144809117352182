import React from 'react';

import { NavBarIcon } from './NavBarIcon';

import {
  StyledNavBlock,
  NavItemText,
  StyledActiveBlock,
  StyledTooltip,
} from './styled';

interface IProps {
  title: string,
  isActive?: boolean,
}

export const Item = ({ title, isActive }: IProps) => (
  <>
    <StyledNavBlock isActive={isActive}>
      <NavBarIcon title={title} />
      <NavItemText className='nav-item-text' isActive={isActive} bold>
        {title}
      </NavItemText>
      <StyledTooltip className='tooltip'>{title}</StyledTooltip>
    </StyledNavBlock>
    {isActive && (
      <StyledActiveBlock />
    )}
  </>
);
