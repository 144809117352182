import { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';

import { FetchResult, useMutation } from '@apollo/react-hooks';
import { ADD_MANUAL_CUSTOMER } from '@/graphql/mutations/addManualCustomer';

import Yup, { allowedUserEmail, DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { IAddress } from '@/types/address';
import { ICompanyUser } from '@/types/companyUser';

interface IFile {
  fileId: string
  path: string
  signedUrl: string
  name: string
  type: string
}

export interface IManualCustomerFormValues {
  company: {
    name: string,
    address: IAddress,
  },
  name: string,
  email: string,
  phoneNumber: string,
  signatureRequiredCreditTerms: boolean,
  signatureRequiredPersonalGuarantee: boolean,
  customMessage: string,
  file: IFile[]
}

export const useForm = (
  currentUser: ICompanyUser,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: (response: FetchResult) => void,
) => {

  const [addManualCustomer] = useMutation(ADD_MANUAL_CUSTOMER);
  const history = useHistory();

  const addressValidation = Yup.object({
    address_line_1: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    address_line_2: Yup.string().nullable(),
    city: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    state: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    postal_code: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    country: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  });

  const customerValidation = Yup.object().shape({
    company: Yup.object().shape({
      name: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
      address: addressValidation,
    }),
    name: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    email: allowedUserEmail(currentUser.email),
    phoneNumber: Yup.string()
      .required(DEFAULT_ERROR_MESSAGES.REQUIRED)
      .test('is-valid-phone', DEFAULT_ERROR_MESSAGES.PHONE, (value) => {
        return !value || Yup.string().phone('US', false).isValidSync(value);
      })
  });

  const addressFields = {
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    postal_code: '',
    country: 'US',
  };

  const customerFields = {
    company: {
      name: '',
      address: addressFields,
    },
    name: '',
    email: '',
    phoneNumber: '',
    signatureRequiredCreditTerms: false,
    signatureRequiredPersonalGuarantee: false,
    customMessage: '',
    file: []
  };

  const handleSubmit = async ({
    company, name, email, phoneNumber, signatureRequiredCreditTerms, signatureRequiredPersonalGuarantee, customMessage, file
  }: IManualCustomerFormValues) => {
    const manualCustomer = {
      companyName: company.name,
      name,
      email,
      phoneNumber,
      address: JSON.stringify(company.address),
      signatureRequiredCreditTerms,
      signatureRequiredPersonalGuarantee,
      customMessage,
    };
    const file_ids = file.map(eachfile => (eachfile.fileId));
    addManualCustomer({ variables: { manualCustomerInput: manualCustomer, uploadedFileIds: file_ids } })
      .then((response) => {
        setIsOpen(false);
        showToast({
          title: 'Customer Added.',
          description: `${company.name} added to your customer list.`,
          type: toast.TYPE.SUCCESS,
        });
        onSuccess && onSuccess(response);
        history.push('/dashboard/customers/'+response.data.addManualCustomer)
      })
      .catch((err) => console.log(err));
  };

  return {
    customerValidation,
    customerFields,
    handleSubmit,
  }
}