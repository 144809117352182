import React from 'react';

import { MutationFunctionOptions, useMutation } from '@apollo/react-hooks';
import { UPDATE_CUSTOMER_SETTINGS } from '@/graphql/mutations/updateCustomerSettings';

import { get } from 'lodash/fp';
import { ICustomer } from '@/types/customer';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { BodyText } from '@/components/designSystem/Typography';
import { Checkbox } from '@/components/designSystem/Form/Checkbox';
import { EnableRequiredOptions, IHandleEnableRequiredOptionsChangeArgs } from '@/components/EnableRequiredOptions';
import { DeleteCustomer } from './DeleteCustomer';
import { ExportData } from './ExportData';
import { ConvertToManual } from './ConvertToManual';

import {
  StyledTabPanelContainer,
  StyledTitle,
  StyledGrayBox,
} from '../../styled';
import { StyledAdditionalActionsButtonContainer, StyledCheckboxWrapper, StyledFlexRowWrapperWithGap } from './styled';
import { CREDIT_APPLICANT_STATUSES } from '@/constants';

const KEYS = {
  enabled: 'enable_personal_guarantee',
  required: 'require_personal_guarantee',
}

interface IProps {
  customerData: ICustomer,
  customerId: string,
  applicationId?: string,
  refetchCustomerData: () => void,
  canSeeEditControl: boolean,
}

export const Preferences = ({ applicationId, customerId, customerData, refetchCustomerData, canSeeEditControl }: IProps) => {
  const title = get('seekerCompany.name', customerData);
  const autoBav = get('settings.autoBav', customerData);
  const enablePersonalGuarantee = get('settings.enablePersonalGuarantee', customerData);
  const requirePersonalGuarantee = get('settings.requirePersonalGuarantee', customerData);
  const isManualCustomer = get('isManualCustomer', customerData);
  const requireBankInfo = get('settings.requireBankInfo', customerData);
  const applicationStatus = get('latestApplication.status', customerData);
  const [updateCustomerSettings] = useMutation(UPDATE_CUSTOMER_SETTINGS);

  const handleBankVerificationChange = () => {
    const settings = {
      enable_personal_guarantee: enablePersonalGuarantee,
      require_personal_guarantee: requirePersonalGuarantee,
      autoBav: !autoBav
    }

    return handleUpdateCompanySettings({ variables: { customerId, settings } });
  }

  const handleRequireBankInformationChange = () => {
    const settings = {
      enable_personal_guarantee: enablePersonalGuarantee,
      require_personal_guarantee: requirePersonalGuarantee,
      autoBav: autoBav,
      requireBankInfo: !requireBankInfo
    }

    return handleUpdateCompanySettings({ variables: { customerId, settings } });
  }

  const handlePersonalGuaranteeChange = (state: any) => handleUpdateCompanySettings({variables: {customerId, settings: state}});

  const handleUpdateCompanySettings = (variables: MutationFunctionOptions) => updateCustomerSettings(variables)
    .then(() => {
      showToast({
        title: 'Settings Successfully Update!',
        description: 'Settings Successfully Update',
        type: toast.TYPE.SUCCESS,
      });
      refetchCustomerData();
    })
    .catch(() => {
      showToast({
        title: 'Error',
        description: 'Settings were not updated',
        type: toast.TYPE.ERROR,
      });
    });

  return (
    <StyledTabPanelContainer>
      <StyledTitle bold>Preferences</StyledTitle>
      <StyledGrayBox>
        {
          !isManualCustomer?
            <>
              <StyledCheckboxWrapper>
                <Checkbox
                  disabled={!canSeeEditControl}
                  checked={autoBav}
                  onChange={handleBankVerificationChange}
                >
                  <BodyText>Enable Automatic Bank Verification</BodyText>
                </Checkbox>
              </StyledCheckboxWrapper>
              {(applicationStatus === CREDIT_APPLICANT_STATUSES.IN_PROGRESS && canSeeEditControl) ? (
                <StyledCheckboxWrapper>
                  <Checkbox
                    checked={requireBankInfo}
                    onChange={handleRequireBankInformationChange}
                  >
                    <BodyText>Require Bank Information</BodyText>
                  </Checkbox>
                </StyledCheckboxWrapper>
              ) : false}
            </>
            : false
        }
        <StyledCheckboxWrapper>
          <StyledFlexRowWrapperWithGap>
            <EnableRequiredOptions
              label='Personal Guarantee'
              keys={KEYS}
              canSeeEditControl={canSeeEditControl}
              enabled={enablePersonalGuarantee}
              required={requirePersonalGuarantee}
              handleChange={handlePersonalGuaranteeChange}
            />
          </StyledFlexRowWrapperWithGap>
        </StyledCheckboxWrapper>
      </StyledGrayBox>
      {canSeeEditControl &&
       <>
         <StyledTitle bold>Additional Actions</StyledTitle>
         <StyledAdditionalActionsButtonContainer>
           <DeleteCustomer customerId={customerId} title={title} />
           <ExportData applicationId={applicationId} />
           {!isManualCustomer && applicationStatus === CREDIT_APPLICANT_STATUSES.IN_PROGRESS &&
             <ConvertToManual customerId={customerId} refetch={refetchCustomerData}/>
           }
         </StyledAdditionalActionsButtonContainer>
       </>
      }
    </StyledTabPanelContainer>
  )
};
