import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro-spa';

import { useMutation } from '@apollo/react-hooks';
import { COMPLETE_ONBOARD_CREDIT_SEEKER } from '@/graphql/mutations/completeOnboardCreditSeeker';

import { get } from 'lodash/fp';

import { IPublicCompany } from '@/types/publicCompany';

import { VisitorContext } from '@/providers/VisitorContext';

import { BodyText, H2 } from '@/components/designSystem/Typography';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Checkbox } from '@/components/designSystem/Form/Checkbox';

import { StyledContainer, StyledSignButton, StyledCheckboxWrapper } from './styled';
import { Agreement } from './Agreement';
import { StyledTitleContainer } from '../../styled';
import { OnboardValuesContext } from '../../context';
import { COMPLETE_PATH } from '../../constants';

export interface IProps {
  providerCompany: IPublicCompany,
}

export const AgreementStep = ({ providerCompany }: IProps) => {
  const visitorData: ExtendedGetResult | null = useContext(VisitorContext);
  const history = useHistory();
  const onboardValues = useContext(OnboardValuesContext);

  const [completeOnboardCreditSeeker] = useMutation(COMPLETE_ONBOARD_CREDIT_SEEKER);

  const [authorizedToSignCheck, setAuthorizedToSignCheck] = useState(false);
  const [authorizedBankCheck, setAuthorizedBankCheck] = useState(false);
  const [agreeToTermsCheck, setAgreeToTermsCheck] = useState(false);

  const isButtonDisabled = !authorizedToSignCheck || !authorizedBankCheck || !agreeToTermsCheck;

  const agreementText = get('agreementText', providerCompany);
  const providerCompanyName = get('name', providerCompany);

  const onAgreementSigned = () => {
    const customerId = onboardValues?.customerId;

    completeOnboardCreditSeeker({ variables: { customerId, visitorData } })
      .then(() => {
        history.push({ pathname: COMPLETE_PATH });
      });
  }

  if (!onboardValues?.customerId) {
    return <LoadingSpinner />
  }

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <H2 bold>{providerCompanyName} Credit Agreement</H2>
      </StyledTitleContainer>
      <Agreement agreementText={agreementText} />
      <StyledCheckboxWrapper>
        <Checkbox checked={authorizedToSignCheck} onChange={() => setAuthorizedToSignCheck((prev) => !prev)}>
          <BodyText bold>I acknowledge my electronic signature is legally binding and I am authorized to apply for credit and enter into this agreement on behalf of my company</BodyText>
        </Checkbox>
      </StyledCheckboxWrapper>
      <StyledCheckboxWrapper>
        <Checkbox checked={authorizedBankCheck} onChange={() => setAuthorizedBankCheck((prev) => !prev)}>
          <BodyText bold>
            {`I hereby authorize the bank, vendors and other sources named herein to release the data and information requested for the purpose of obtaining `}
            {`and/or reviewing my company's credit and/or for other services offered by Nectarine Credit`}
          </BodyText>
        </Checkbox>
      </StyledCheckboxWrapper>
      <StyledCheckboxWrapper>
        <Checkbox checked={agreeToTermsCheck} onChange={() => setAgreeToTermsCheck((prev) => !prev)}>
          <BodyText bold>I agree to the Terms and Conditions set forth in this credit agreement</BodyText>
        </Checkbox>
      </StyledCheckboxWrapper>
      <StyledSignButton customerId={onboardValues.customerId} onSuccess={onAgreementSigned} disabled={isButtonDisabled}>Sign Agreement</StyledSignButton>
    </StyledContainer>
  )};
