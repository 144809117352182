import React from 'react';

import {
  PageGrid,
  ContentContainer,
  MarketingSectionContainer,
  StyledLogo,
  InnerContentContainer,
} from './styled';

interface IProps {
  children: React.ReactNode,
}

const MarketingSection = () => (<MarketingSectionContainer />);

const Content = ({ children }: IProps) => (
  <ContentContainer>
    <InnerContentContainer>
      <StyledLogo />
      {children}
    </InnerContentContainer>
  </ContentContainer>
);

export const LoginContainer = ({ children }: IProps) => (
  <PageGrid>
    <Content>
      {children}
    </Content>
    <MarketingSection />
  </PageGrid>
);
