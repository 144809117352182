import React from 'react';
import { YellowWarningIcon } from '@/components/icons';
import styled from 'styled-components';

const StyledYellowWarning = styled.div`
  padding-left: 0.25rem;
  cursor: pointer;
`

export const EmailWarningIcon = () => (
  <StyledYellowWarning
    title='Web-domain of this email indicates a personal and not business email address. Proceed with caution'>
    <YellowWarningIcon />
  </StyledYellowWarning>
)

