import React from 'react';

import { OnboardTime } from '@/components/Onboard/OnboardTime';
import { OnboardLogo } from '@/components/Onboard/OnboardLogo';
import { StepCounter } from '@/components/Onboard/StepCounter';
import { Callout } from '@/components/Callout/Callout';

import {
  StyledOnboardHeader,
  StyledOnboardHeaderWrapper,
  StyledStepCounterContainer,
  StyledCalloutContainer,
} from './styled';

interface ISteps {
  [key: string]: string
}

interface IProps {
  overriddenLogo?: JSX.Element | null,
  index?: number,
  steps: ISteps,
  providerCompanyName?: string | null,
  providerCompanyEmail?: string | null,
  providerCompanyPhone?: string | null,
  minutes?: number
}

export const StepHeader = ({
  overriddenLogo = null,
  index = 0, steps,
  providerCompanyName,
  providerCompanyEmail,
  providerCompanyPhone,
  minutes = 0,
}: IProps) => (
  <StyledOnboardHeaderWrapper>
    <StyledOnboardHeader>
      <OnboardLogo overriddenLogo={overriddenLogo} />
      <StyledStepCounterContainer steps={Object.values(steps)}>
        <StepCounter steps={Object.values(steps)} activeStep={index} />
      </StyledStepCounterContainer>
      <OnboardLogo overriddenLogo={overriddenLogo} opacity={0} />
    </StyledOnboardHeader>
    { minutes ? (
      <OnboardTime minutes={minutes} />
    ) : false }
    {(providerCompanyName && providerCompanyEmail) ? (
      <StyledCalloutContainer>
        <Callout color='label.default'>
          If you have any questions, please contact {providerCompanyName} at {providerCompanyEmail} or {providerCompanyPhone}.
        </Callout>
      </StyledCalloutContainer>
    ) : false }
  </StyledOnboardHeaderWrapper>
);
