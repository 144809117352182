import React from 'react';

import { StyledSideTitleBlock, StyledSideTitle, StyledSideTitleLine } from './styled';

interface IProps {
  title: string,
}

export const SideTitle = ({ title }: IProps) => (
  <StyledSideTitleBlock>
    <StyledSideTitle bold>{title}</StyledSideTitle>
    <StyledSideTitleLine />
  </StyledSideTitleBlock>
);
