import React from 'react';

import { ICustomField } from '@/types/customField';

import { CardsContainer } from '@/components/designSystem/cards/CardsContainer';

import { SectionContainer } from '../../SectionContainer';
import { AdditionalQuestion } from './AdditionalQuestion';

interface IAdditionalQuestionAnswer {
  customField: ICustomField,
  id: string,
  text: string,
}

interface IProps {
  additionalQuestionsAnswers: IAdditionalQuestionAnswer[],
  refetch: () => void,
  customerId: string,
}

export const AdditionalQuestions = ({ additionalQuestionsAnswers, refetch, customerId }: IProps) => (
  <SectionContainer title='Additional Questions'>
    <CardsContainer>
      {additionalQuestionsAnswers.map((additionalQuestionAnswer) => (
        <AdditionalQuestion
          key={additionalQuestionAnswer.id}
          id={additionalQuestionAnswer.id}
          text={additionalQuestionAnswer.text}
          customField={additionalQuestionAnswer.customField}
          refetch={refetch}
          customerId={customerId}
        />
      ))}
    </CardsContainer>
  </SectionContainer>
);
