import gql from 'graphql-tag';

export const GET_CUSTOMERS = gql`
  query GetCustomers {
    customizedCurrentCompany {
      id
      customFields {
        id
        type
        label
      }
      customers {
        id
        isManualCustomer
        customFieldAnswers {
          id
          text
          customField {
            id
            type
            label
            description
            required
            allowMultipleUploads
            options
            contact {
              showName
              nameRequired
              showEmail
              emailRequired
              showPhone
              phoneRequired
              positionRequired
              showExtension
              extensionRequired
            }
            yesToggle {
              type
              allowMultipleUploads
            }
            noToggle {
              type
              allowMultipleUploads
            }
          }
        }
        seekerCompany {
          id
          name
        }
        latestApplication {
          id
          status
          providerDisplayStatus
          creditLimit
          requestedCreditLimit
          einFederalTaxNumber
          creditTerms
          dateApproved
          updatedAt
          lastUpdatedBy {
            id
            name
          }
        }
      }
      pendingCustomerInvites {
        id
        toEmail
        toCompanyName
        type
        from {
          id
          name
        }
        referralCode
        sent
      }
    }
  }
`;
