import React, { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { UPDATE_CUSTOM_FIELD_ANSWER } from '@/graphql/mutations/updateCustomFieldAnswer';

import { Formik, Form } from 'formik';
import Yup from '@/utils/yup';

import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { ADDITIONAL_QUESTION_DEFAULT_OPTION } from '@/constants';

import { ICustomField } from '@/types/customField';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { BodyText, H3 } from '@/components/designSystem/Typography';
import { EditableCard } from '@/components/designSystem/cards/EditableCard';
import { SelectField } from '@/components/designSystem/Form/SelectField';
import { Button } from '@/components/designSystem/buttons';

import { StyledAdditionalQuestionInfoContainer } from './styled';
import { StyledButtonsContainer } from '../../styled';

interface IFormValues {
  [key: string]: string
}

interface IProps {
  customField: ICustomField,
  id: string,
  text: string,
  refetch: () => void,
  customerId: string,
}

export const AdditionalQuestion = ({ id, text, customField, refetch, customerId }: IProps) => {

  const [isEditActive, setEditActive] = useState(false);

  const [updateCustomFieldAnswers, { loading }] = useMutation(UPDATE_CUSTOM_FIELD_ANSWER);

  const initialValues = {
    [customField.id]: text
  }

  const validationSchema = Yup.object().shape({
    [customField.id]: Yup.string()
  })

  const handleSubmit = (formValues: IFormValues) => {
    updateCustomFieldAnswers({ variables: {
      customerId,
      customFieldAnswer: {
        customFieldId: Object.keys(formValues)[0],
        text: Object.values(formValues)[0]
      }
    } })
      .then(() => {
        showToast({
          title: 'Information Successfully Updated!',
          description: 'Your customer contact information successfully updated.',
          type: toast.TYPE.SUCCESS,
        });
        setEditActive(false);
        refetch();
      })
      .catch(() => {
        showToast({
          title: 'Error',
          description: 'Information wasn\'t updated',
          type: toast.TYPE.ERROR,
        });
        setEditActive(false);
      });
  }

  return (
    <EditableCard key={id} isEditActive={isEditActive} setEditActive={setEditActive}>
      <StyledAdditionalQuestionInfoContainer>
        <H3 bold>{customField.label}</H3>
        {isEditActive
          ? (
            <Formik onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)} initialValues={initialValues} validationSchema={validationSchema}>
              <Form>
                <SelectField
                  required={customField.required}
                  name={customField.id}
                  defaultValue={text ?? undefined}
                  options={customField.options?.map((option) => ({ label: option, value: option })).concat([ADDITIONAL_QUESTION_DEFAULT_OPTION])}
                />
                <StyledButtonsContainer>
                  <Button onClick={() => setEditActive(false)} secondary>Cancel</Button>
                  <Button
                    type='submit'
                    primary
                    loading={loading}
                  >
                    Save
                  </Button>
                </StyledButtonsContainer>
              </Form>
            </Formik>
          )
          : (
            <BodyText>{text}</BodyText>
          )}
      </StyledAdditionalQuestionInfoContainer>
    </EditableCard>
  )};
