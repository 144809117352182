import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { getOr } from 'lodash/fp';

import { GET_DASHBOARD_OVERVIEW } from '@/graphql/queries/getDashboardOverview';

import { IGetDashboardOverviewData } from '@/types/querries';

import { LoadingSpinner } from '@/components/LoadingSpinner';

const DefaultDashboardRoute = () => {
  const { loading, data } = useQuery<IGetDashboardOverviewData>(GET_DASHBOARD_OVERVIEW);

  if (loading) {
    return <LoadingSpinner />;
  }

  const customers = getOr([], 'currentCompany.customers', data);
  const pendingCustomerInvites = getOr([], 'currentCompany.pendingCustomerInvites', data);

  const customerOf = getOr([], 'currentCompany.customerOf', data);
  const givenReferences = getOr([], 'currentCompany.givenReferences', data);

  if (customers.length > 0 || pendingCustomerInvites.length > 0) {
    return <Redirect to='/dashboard/customers' />;
  }
  if (customerOf.length > 0) {
    return <Redirect to='/dashboard/requests' />;
  }
  if (givenReferences.length > 0) {
    return <Redirect to='/dashboard/references' />;
  }
  return <Redirect to='/dashboard/customers' />;
};

export default withRouter(DefaultDashboardRoute);
