import styled from 'styled-components';

import { Form } from 'formik';
import { H4 } from '@/components/designSystem/Typography';
import device from '@/styles/device';

import { Flex, FlexColumn, FlexRowSpaceBetweenItemsCenter } from '@/components/designSystem/Layout';
import { BorderedContainer } from '@/containers/BorderedContainer';

export const StyledContainer = styled(FlexColumn)`
  & > * {
    margin-bottom: 2rem;
  }
`;

export const StyledForm = styled(Form)`
  & > * {
    margin-bottom: 2rem;
  }
`;

// CUSTOM FIELDS

export const StyledVRCustomFieldsContainer = styled(BorderedContainer)`
  & > * {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  & > :last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
`;

interface IStyledCustomFieldRow {
  first?: boolean;
}

export const StyledCustomFieldRow = styled(FlexRowSpaceBetweenItemsCenter)<IStyledCustomFieldRow>`
  border-top: ${({ first }) => first ? 'none' : 'solid 0.02em #ECECEC'};
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 70%;

  @media ${device.mobileXL} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 0.5rem 0rem 0rem;

    & > * {
      margin-bottom: 1rem;
    }
  }
`;

export const StyledOrientationContainer = styled(Flex)`
  gap: 1rem;
  @media ${device.tablet} {
    flex-direction: column;
  }
`

export const StyledOrientationDirection = styled(Flex)`
  flex-direction: column;
  flex-basis: 100%;
`

export const StyledOrientationH4 = styled(H4)`
  margin-bottom: 1rem;
`
