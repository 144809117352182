import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { get } from 'lodash/fp';

import { IApplication } from '@/types/application';

export const useFields = (application: IApplication, requireLimits: boolean, seekerDenialAlert: boolean, seekerApprovalAlert: boolean) => {
  const creditLimit = get('creditLimit', application);
  const creditTerms = get('creditTerms', application);
  const status = get('status', application);
  const providerComment = get('providerComment', application) || '';

  const fields = {
    creditLimit,
    creditTerms,
    status,
    providerComment,
    seekerDenialAlertEmail: seekerDenialAlert,
    seekerApprovalAlertEmail: seekerApprovalAlert,
  };

  const validation = Yup.object().shape({
    creditLimit: requireLimits ? Yup.number().required(DEFAULT_ERROR_MESSAGES.REQUIRED) : Yup.number().nullable(true),
    creditTerms: requireLimits ? Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED) : Yup.string().nullable(true),
    status: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    providerComment: Yup.string(),
  });

  return { fields, validation };
}