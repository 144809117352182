import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useMutation } from '@apollo/react-hooks';
import { UPDATE_CREDIT_TERMS } from '@/graphql/mutations/updateCreditTermOptions';

import { extractGQLErrorMessage } from '@/utils/extractGQLErrorMessage';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { TextField } from '@/components/designSystem/Form/TextField';
import { FlexRow } from '@/components/designSystem/Layout';
import { Button } from '@/components/designSystem/buttons';
import { SetCreditTermsOptionsModal } from '@/modals/SetCreditTermsOptionsModal';

import { StyledClose } from './styled';

const Container = styled(FlexRow)`
  width: 100%;
  align-items: center;

  & > * {
    margin-left: 1rem;
  }

  & > :first-child {
    margin-left: 0;
  }
`;

interface IProps {
  creditTermsOptions: string[],
  refetch: () => void,
}

export const CreditTermFields = ({
  creditTermsOptions,
  refetch,
}: IProps) => {
  const [isSetOptionsOpen, setSetOptionsOpen] = useState(false);
  const [creditTermsOptionsState, setСreditTermsOptionsState] = useState(creditTermsOptions);

  const [updateCreditTerms] = useMutation(UPDATE_CREDIT_TERMS);

  const variables = {
    settings: {
      attach_credit_options: false,
    },
  };

  useEffect(() => {
    setСreditTermsOptionsState(creditTermsOptions);
  }, [creditTermsOptions]);

  const onClick = () => updateCreditTerms({ variables })
    .then(refetch)
    .catch((error) => {
      showToast({
        title: 'Error',
        description: extractGQLErrorMessage(error),
        type: toast.TYPE.ERROR,
      });
    });
  // set booleans false

  return (
    <>
      <Container>
        {/* This TextField name is fake. We need it for formik fields.
        If you need to enable this text field at some point, you will need to fix name. */}
        <TextField type='text' name='creditTerms' disabled label='Credit Terms' placeholder={creditTermsOptions[0]} />
        <Button primary onClick={() => setSetOptionsOpen(true)}>Set Options</Button>
        <StyledClose onClick={onClick} />
      </Container>
      <SetCreditTermsOptionsModal
        isOpen={isSetOptionsOpen}
        setIsOpen={setSetOptionsOpen}
        onSuccess={refetch}
        creditTermsOptions={creditTermsOptionsState}
        setСreditTermsOptionsState={setСreditTermsOptionsState}
      />
    </>
  );
};
