import React from 'react';

import { get, getOr } from 'lodash/fp';
import { formatCurrency } from '@/utils/money';
import { mapStatusLabel } from '@/utils/format';

import { ICustomer } from '@/types/customer';
import { ILabelValue } from '@/types/field';
import { Row } from '@/components/designSystem/Form/styled';
import { BodyText, H1, H2 } from '@/components/designSystem/Typography';
import { StatusLabel } from '@/components/Label/StatusLabel';
import { Flex } from '@/components/designSystem/Layout';

import {
  StyledMainInfoContainer,
  StyledMainInfoValuesContainer,
} from './styled';

const MainInfoValue = ({ label, value }: ILabelValue) => (
  <Flex direction='column' justify='flex-end'>
    <H2>{value}</H2>
    <BodyText color='secondary'>{label}</BodyText>
  </Flex>
);

interface IProps {
  customerData: ICustomer,
}

export const MainInfo = ({ customerData }: IProps) => {
  const application = get('latestApplication', customerData);
  const isManualCustomer = get('isManualCustomer', customerData);
  const creditLimit = getOr('--', 'creditLimit', application);
  const creditTerms = getOr('--', 'creditTerms', application);
  const status = getOr('', 'providerDisplayStatus', application);
  const isPending = status === 'pending_approval';

  return (
    <StyledMainInfoContainer>
      <Flex justify='space-between' align='center'>
        <div>
          <H1 bold>{get('seekerCompany.name', customerData)}</H1>
          <Row>
            <StatusLabel value={status} />
            {
              isManualCustomer ?
                <StatusLabel value='Manual Customer' />
                : null
            }
          </Row>
        </div>
        <StyledMainInfoValuesContainer>
          <MainInfoValue
            value={typeof creditLimit === 'number' ? formatCurrency(creditLimit) : '--'}
            label={isPending ? 'Requested Credit limit' : 'Credit limit'}
          />
          <MainInfoValue
            value={creditTerms || '--'}
            label={isPending ? 'Requested Terms' : 'Terms'}
          />
        </StyledMainInfoValuesContainer>
      </Flex>
    </StyledMainInfoContainer>
  );
};
