import React, { useReducer } from 'react';
import { useLocation } from 'react-router';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { get, getOr } from 'lodash/fp';
import { getWithBackup } from '@/utils/object';

import { IReferralData } from '@/types/referralData';

import { EmailVerification } from '@/views/onboard/CreditApplicantOnboard/EmailVerification';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { basicInfoInitialValues } from './steps/BasicInfoStep/initialValues';
import { companyProfileInitialValues } from './steps/CompanyProfileStep/initialValues';
import { companyContactsInitialValues } from './steps/CompanyContactsStep/initialValues';
import { vendorsInitialValues } from './steps/VendorsStep/initialValues';
import { financialHealthInitialValues } from './steps/FinancialHealthStep/initialValues';
import { otherInfoInitialValues } from './steps/OtherInfoStep/initialValues';
import { StepWrapper } from './StepWrapper';
import { useTheme } from './useTheme';
import { OnboardValuesContext } from './context';
import { StyledContainer } from './styled';
import { DEFAULT_STEP } from './constants';
import { onboardValuesReducer } from './reducer';
import { getSteps } from './functions';
import { useQuery } from '@apollo/react-hooks';
import { GET_COMPANY_BY_ID } from '@/graphql/queries/getCompanyById';

const serviceLocationInitialValues = (refData: any) => {
  return getOr([], 'refData.getCustomerAsSeeker.serviceLocations', refData);
}
const getInitialOnboardValues = (
  refData: IReferralData,
  customerId: string | null,
  basePath: string
) => {
  return ({
  basicInfo: basicInfoInitialValues(refData),
  companyProfile: companyProfileInitialValues(refData),
  companyContacts: companyContactsInitialValues(refData),
  financialHealth: financialHealthInitialValues(refData),
  vendors: vendorsInitialValues(refData),
  otherInfo: otherInfoInitialValues(refData),
  serviceLocations: serviceLocationInitialValues(refData),

  emailVerified: false,
  customerId,
  basePath,
})
};

interface IProps {
  refData: IReferralData,
  basePath: string,
  referralCode: string | null,
  customerId: string | null,
}

export const CreditApplicantOnboardContainer = ({ refData, basePath, referralCode, customerId }: IProps) => {
  const { search } = useLocation();

  const stepToRedirectTo = getWithBackup('getCustomerAsSeeker.onboardStep', 'getReferralData.customer.onboardStep', refData) || DEFAULT_STEP;
  const startPath = `${basePath}/${stepToRedirectTo}`

  // CP options

  const providerCompany = getOr(null, 'getCompanyById', refData);
  const companySettings = get('settings', providerCompany);

  const providerCompanyName = get('name', providerCompany);
  const providerCompanyId = get('id', providerCompany);

  const { data: companyByIdData } = useQuery(GET_COMPANY_BY_ID, {
    variables: {
      companyId: providerCompanyId
    },
  });

  const providerCompanyEmail = get('getCompanyById.providerInfo.contactEmail', companyByIdData);
  const providerCompanyPhone = get('getCompanyById.providerInfo.contactPhone', companyByIdData);
  // Theme

  const { overriddenTheme, logoUrl, brand } = useTheme(providerCompany);

  const overriddenLogo = logoUrl
    ? <img src={logoUrl} alt='logo' />
    : null;

  const [onboardValues, dispatch] = useReducer(
    onboardValuesReducer,
    getInitialOnboardValues(
      refData,
      customerId,
      basePath
    )
  );

  if (!companySettings || !companyByIdData) {
    return <LoadingSpinner />
  }

  const steps = getSteps({
    refData,
    dispatch,
    referralCode
  });

  return (
    <ThemeProvider theme={overriddenTheme}>
      <StyledContainer>
        <Switch>
          <OnboardValuesContext.Provider value={
            onboardValues
          }>
            {steps.map(({
              path, Component, stepProps
              // Fix any
            } : any, index) => (
              <Route
                key={path}
                path={`${basePath}/${path}`}
                render={() =>
                  <StepWrapper
                    prevPath={index ? `${basePath}/${steps[index - 1].path}` : null}
                    providerCompanyName={index !== 0 ? providerCompanyName : undefined}
                    providerCompanyEmail={index !== 0 ? providerCompanyEmail : undefined}
                    providerCompanyPhone={index !== 0 ? providerCompanyPhone : undefined}
                    minutes={index !== 0 ? 5 : undefined}
                    index={index - 1}
                    overriddenLogo={overriddenLogo}
                    brand={brand}
                  >
                    <Component {...stepProps} />
                  </StepWrapper>
                }
              />
            ))}
            <Route path={`${basePath}/emailverification`} component={EmailVerification} />
            <Redirect from={basePath} to={`${startPath}${search}`} />
          </OnboardValuesContext.Provider>
        </Switch>
      </StyledContainer>
    </ThemeProvider>
  )
}
