import gql from 'graphql-tag';
import { CUSTOMER_FRAGMENT } from '@/graphql/queries/getCustomer';

const PUBLIC_COMPANY_FRAGMENT = gql`
  fragment PublicCompanyFragment on PublicCompany {
    id
    agreementText
    name
    numRequiredReferences
    numTotalReferences
    brand {
      id
      logo {
        id
        path
        originalFilename
        signedUrl
      }
      primaryColor
      secondaryColor
      brandColor
      backgroundColor
    }
    creditTerms
    customFields {
      id
      type
      label
      description
      required
      allowMultipleUploads
      options
      contact {
        showName
        nameRequired
        showEmail
        emailRequired
        showPhone
        phoneRequired
        positionRequired
        showExtension
        extensionRequired
      }
      yesToggle {
        type
        allowMultipleUploads
        description
      }
      noToggle {
        type
        allowMultipleUploads
        description
      }
    }
    vrCustomFields {
      id
      type
      label
      step
      name
      required
      enabled
      updatedAt
    }
    settings {
      autoBavDefault
      attachCreditOptions
      requireEinFederalTaxNumber
      requirePersonalGuaranteeDefault
      requestBankStatements
      enableVrCreditSafeIncentive
      enableAddress
      requireAddress
      enableAddressFor
      enableSsnSinNumber
      requireSsnSinNumber
      enableSsnSinNumberFor
      enableDriverLicenseNo
      requireDriverLicenseNo
      enableDriverLicenseNoFor
      enableDriverLicenseState
      requireDriverLicenseState
      enableDriverLicenseStateFor
      enableDateOfBirth
      requireDateOfBirth
      enableDateOfBirthFor
      requireBankInfo
      enableShippingAddress
      enableBillingAddress
      requireShippingAddress
      requireBillingAddress
      requireOwnershipStake
      enableOwnershipStake
      enableOwner
      requireOwner
      enableServiceLocations
      requireServiceLocations
    }
  }
`;

export const GET_REFERRAL_DATA = gql`
  query GetReferralData($referralCode: String, $companyId: ID, $customerId: ID) {
    getReferralData(referralCode: $referralCode) {
      id
      toCompanyName
      toName
      toPosition
      toPhoneNumber
      toEmail
      referralUrl
      autoBav
      requirePersonalGuarantee
      requireBankInfo
      deleted_at
      complete
      reward_report{
        id
        signedURL
      }
      sent
      blockSend
      customer {
        id
        onboardStep
      }
      from {
        id
        name
      }
      fromCompany {
        ...PublicCompanyFragment
      }
      forCompany
      {
        ...PublicCompanyFragment
      }
      activityFeed {
        occuredAt
        description
      }
    }

    getCompanyById(companyId: $companyId) {
      ...PublicCompanyFragment
    }

    getCustomerAsSeeker(customerId: $customerId) {
      ...CustomerFragment
    }

    currentUser {
      id
      name
      email
      emailVerified
      userCompanyRole {
        isAdmin
        creditLimit
        company {
          id
        }
        role {
          name
          isCreditLimitApply
          permissions {
            inviteCustomer
            readOnly
            inviteUser
            changeTemplate
          }
        }
      }
    }

    currentCompany {
      id
      name
      otherNames
      type
      address {
        address_line_1
        address_line_2
        city
        state
        country
        postal_code
      }
      phoneNumber
      website
      established
      description
      currentUserStakeholder {
        id
        position
        ownershipPercentage
        phoneNumber
      }
    }
  }
  ${PUBLIC_COMPANY_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
`;
