import styled from 'styled-components';
import { Form } from 'formik';

import { Flex, FlexColumn } from '@/components/designSystem/Layout';
import { Button } from '@/components/designSystem/buttons';
import device from '@/styles/device';

import CreditSafeLogo from '@/assets/CreditSafeLogo.png';
import Indicator from '@/assets/indicator.png';
import { Caption } from '@/components/designSystem/Typography';

export const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header"
    "main";
  height: 100vh;
  background-color: ${(props) => props.theme.color.site.background};
`;

export const StyledStepCounterContainer = styled.div`
  margin: 3rem 0 3rem 3rem;
`;

export const StyledMain = styled.main`
  grid-area: main;
  border-radius: 1.25rem;
  background-color: ${(props) => props.theme.color.white};
  box-shadow: ${({ theme }) => theme.color.shadow.shadowLight};
  min-height: 100vh;
  height: fit-content;
  padding: 2rem;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-template-areas:
    "left center center center center right";
  
  @media ${device.mobileXL} {
    padding: 2rem 1rem;
    grid-template-rows: repeat(1, minmax(0, 1fr));
    grid-template-columns: 1fr;
    grid-template-areas:
      "left"
      "center"
      "right";
  };
`;

export const StyledLeftBlock = styled.div`
  grid-area: left;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: start;
  
  @media ${device.mobileXL} {
    position: sticky;
    top: 20px;
  };
`

export const StyledRightBlock = styled.div`
  grid-area: right;
  
  @media ${device.mobileXL} {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
  };
`

export const StyledFormContainer = styled(FlexColumn)`
  grid-area: center;
  align-items: center;
`;

export const StyledPoweredByContainer = styled.a`
  position: fixed;
  bottom: 0;
  left: 2rem;
  width: 100px;

  & > * {
    width: 100%;
  }
  
  @media ${device.mobileXL} {
    position: relative;
    left: 0rem;
  };
`;

// STEP

export const StyledTitleContainer = styled(FlexColumn)`
  margin-bottom: 1.5rem;
`;

export const StyledSubtitleContainer = styled(FlexColumn)`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;

export const StyledForm = styled(Form)`
  width: 60vw;
  max-width: 60rem;
`;

export const StyledCheckboxWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const StyledNextButton = styled(Button)`
  margin-top: 1rem;
  width: 13rem;
`;

// REWARD

export const StyledCSLogoSM = styled.img.attrs({src: CreditSafeLogo})`
  height: 50px;  
`;
export const StyledIndicatorSM = styled.img.attrs({src: Indicator})`
  width: 60%;
`;

export const StyledRewardBoxContainer = styled.div`
  margin: auto;
  position: fixed;
  top: 0%; left: auto; bottom: 0; right: 1%;
  width: calc(20vw - 2%);
  height: min-content;
  padding: 2px;
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) => theme.color.shadow.shadowLarge};
  background-color: ${({ color }) => color};
  
  @media ${device.tablet} {
    display: none;
  };
`;

export const StyledRewardBoxitem = styled(Flex).attrs({ direction: 'column', justify: 'center', align: 'center' })`
  width: 100%;
`;

export const StyledRewardCaption = styled(Caption)`
  padding-top: 10px;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  text-align: center;
  color: ${({ color }) => color};
`;