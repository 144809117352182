import React, { useContext, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { Tabs, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useLocalStorage } from '@rehooks/local-storage';

import { useQuery } from '@apollo/react-hooks';
import { GET_CUSTOMER_AS_PROVIDER } from '@/graphql/queries/getCustomer';
import { GET_PARENT_COMPANY } from '@/graphql/queries/getParentCompany';

import { get } from 'lodash/fp';
import { roleFallback } from '@/utils/roleFallback';

import { IGetCustomerAsProviderData } from '@/types/querries';
import { IUserCompanyRole } from '@/types/companyUser';

import { CurrentUserContext } from '@/providers/CurrentUser';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { FlexRowItemsCenter } from '@/components/designSystem/Layout';

import { EmailActivityFeed } from './EmailActivityFeed';
import { History } from './History';
import { MainInfo } from './MainInfo';
import { Actions } from './Actions';
import { CompanyInfo } from './tabs/CompanyInfo';
import { BureauReport } from './tabs/BureauReport';
import { References } from './tabs/References';
import { Bank } from './tabs/Bank';
import { Preferences } from './tabs/Preferences';
import { AdditionalInfo } from './tabs/AdditionalInfo';
import { StyledContainer, StyledTabList, StyledTab, StyledSideContainer } from './styled';
import { WarningIcon } from './WarningIcon';

interface ITab {
  title: string | JSX.Element,
  icon?: JSX.Element,
  Component: JSX.Element,
  id: string,
}

interface IParams {
  customerId: string,
}

export const BankVerificationStatus = React.createContext("");

export const CustomerPage = () => {
  const [companyId] = useLocalStorage('company-id');
  const { customerId } = useParams<IParams>();
  const [tabIndex, setTabIndex] = useState(0);

  const {
    data: getCustomerAsProviderData,
    loading,
    error: getCustomerAsProviderError,
    refetch: refetchCustomerData
  } = useQuery<IGetCustomerAsProviderData>(GET_CUSTOMER_AS_PROVIDER, { variables: { customerId }, fetchPolicy: 'no-cache' });
  const { data: parentCompany } = useQuery(GET_PARENT_COMPANY);

  const currentUserData = useContext(CurrentUserContext);
  const currentCompanyData = useContext(CurrentCompanyContext);

  const parentCompanyId = get('parentCompany.id', parentCompany);
  const currentCompanyId = get('currentCompany.id', currentUserData) || companyId;
  const userCompanyRoles = get('currentUser.userCompanyRole', currentUserData);
  // eslint-disable-next-line max-len
  const currentUserCompanyRole = roleFallback(userCompanyRoles.filter((ucr: IUserCompanyRole) => ucr.company.id === currentCompanyId), userCompanyRoles.filter((ucr: IUserCompanyRole) => ucr.company.id === parentCompanyId))
  // const isAdmin = get('isAdmin', currentUserCompanyRole[0])
  const isReadOnly = get('role.permissions.readOnly', currentUserCompanyRole[0]) || false;

  const canSeeEditControl = !isReadOnly;

  const customer = get('getCustomerAsProvider', getCustomerAsProviderData);
  const application = get('latestApplication', customer);
  const providerSettings = get('providerCompany.settings', customer)
  const seekerApprovalAlert = get('seekerApprovalAlert', providerSettings)
  const seekerDenialAlert = get('seekerDenialAlert', providerSettings)
  const applicationId = get('id', application);
  const creditTermsOptions = get('currentCompany.creditTerms', currentCompanyData);

  const isManualCustomer = get('isManualCustomer', customer);
  const numberOfManualInstitutions: number = get('seekerCompany.manualInstitutions.length', customer)
  const numberOfAutomaticAccounts: number =  get('seekerCompany.finicityAccountProjections.length', customer)
  const numberOfPendingVendorInvites: number = get('seekerCompany.pendingVendorInvites.length', customer)
  const numberOfReceivedReferences: number = get('seekerCompany.receivedReferences.length', customer)
  const numberOfVendors: number = numberOfPendingVendorInvites + numberOfReceivedReferences;

  const bankruptcies = get('getBankruptcies', getCustomerAsProviderData);

  if (getCustomerAsProviderError) {
    return (
      <Redirect to='/dashboard'/>
    )
  }

  if (!customer || loading) {
    return <LoadingSpinner />
  }

  const TABS = [
    {
      id: '1',
      title: 'Company info',
      Component: <CompanyInfo canSeeEditControl={canSeeEditControl} customerData={customer} bankruptcies={bankruptcies} refetchCustomerData={refetchCustomerData} providerSettings={providerSettings}/>
    },
    {
      id: '2',
      title: 'Bureau report',
      Component: <BureauReport canSeeEditControl={canSeeEditControl} customerData={customer} customerId={customerId} refetchCustomerData={refetchCustomerData} />
    },
    {
      id: '3',
      title: 'References',
      icon: <WarningIcon />,
      Component: <References
        canSeeEditControl={canSeeEditControl}
        customerData={customer}
        refetchCustomerData={refetchCustomerData}
      />
    },
    { id: '4', title: 'Bank info', icon: <WarningIcon />, Component: <Bank customerData={customer} refetchCustomerData={refetchCustomerData} /> },
    {
      id: '5',
      title: 'Preferences',
      Component: <Preferences
        canSeeEditControl={!isReadOnly}
        customerData={customer}
        customerId={customerId}
        applicationId={applicationId}
        refetchCustomerData={refetchCustomerData}
      />
    },
    { id: '6', title: 'Additional info',  Component: <AdditionalInfo canSeeEditControl={!isReadOnly} customerData={customer} refetchCustomerData={refetchCustomerData} /> },
  ];

  return (
    <StyledContainer>
      {/* <Hints enabled={hintsEnabled} hints={hints}/> */}
      <BankVerificationStatus.Provider value={customer.bankVerificationStatus}>
        <MainInfo customerData={customer} />
        {application && (
          <StyledSideContainer>
            {!isReadOnly && (
              <Actions
                customerData={customer}
                application={application}
                creditTermsOptions={creditTermsOptions}
                seekerApprovalAlert={seekerApprovalAlert}
                seekerDenialAlert={seekerDenialAlert}
                refetchCustomerData={refetchCustomerData}
              />
            )}
            {application.auditHistory.length ? (
              <History history={application.auditHistory} />
            ) : false}
            <EmailActivityFeed
              canSeeEditControl={!isReadOnly}
              customerData={customer}
              customerId={customerId}
              refetchCustomerData={refetchCustomerData}
            />
          </StyledSideContainer>
        )}
        <Tabs selectedIndex={tabIndex} onSelect={(index: number) => setTabIndex(index)}>
          <StyledTabList>
            {TABS.map((tab: ITab) => (
              <StyledTab key={tab.id}>
                <FlexRowItemsCenter>
                  {
                    (isManualCustomer && numberOfVendors < 1 && tab.id === '3')? tab.icon : null
                  }
                  {
                    (isManualCustomer && numberOfManualInstitutions < 1 && numberOfAutomaticAccounts < 1 && tab.id === '4')? tab.icon : null
                  }
                  {tab.title}
                </FlexRowItemsCenter>
              </StyledTab>
            ))}
          </StyledTabList>
          {TABS.map((tab: ITab) => <TabPanel key={tab.id}>{tab.Component}</TabPanel>)}
        </Tabs>
      </BankVerificationStatus.Provider>
    </StyledContainer>
  );
};
