/* eslint-disable react/no-array-index-key */
import React from 'react';

import { INavOptionsGroup } from '@/types/navOptions';

import { NavigationItem } from './NavigationItem';
import { LinkItem } from './LinkItem';

import { NavGroupContainer, StyledDivider } from './styled';

interface IProps {
  options: INavOptionsGroup,
  index: number,
  toggleMenu?: () => void,
}

export const NavigationGroup = ({ options, index, toggleMenu }: IProps) => (
  <>
    <NavGroupContainer index={index}>
      {options.map((option, i) => (option.path
        ? <NavigationItem key={i} {...option} toggleMenu={toggleMenu} />
        : <LinkItem key={i} {...option} toggleMenu={toggleMenu} />))}
    </NavGroupContainer>
    {(index === 0) && (
      <StyledDivider />
    )}
  </>
);
