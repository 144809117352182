import React from 'react';

import { formatDateToTimeAgo } from '@/utils/date';

import { Small, Caption } from '@/components/designSystem/Typography';
import { Flex } from '@/components/designSystem/Layout';

interface IProps {
  occuredAt: string,
  description: string,
}

export const ActivityItem = ({ occuredAt, description }: IProps) => (
  <Flex justify='space-between' align='center'>
    <Flex direction='column'>
      <Caption>{description}</Caption>
    </Flex>
    <Small color='secondary'>{formatDateToTimeAgo(occuredAt)}</Small>
  </Flex>
);
