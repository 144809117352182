import React from 'react';

import { get } from 'lodash/fp';

import { ICustomer } from '@/types/customer';

import { BorderedContainer } from '@/containers/BorderedContainer';
import TwoColumnContainer from '@/components/_oldComponents/TwoColumnContainer';
import { ApplicationInfo } from '@/views/ApplicationPage/ApplicationInfo';

import { SignedAgreements } from './SignedAgreements';
import { ApplicationAction } from './ApplicationAction';
import { CreditSubContainer } from './styled';

interface ICreditAgreementSectionProps {
    customer: ICustomer,
    refetch: () => void,
  }

export const CreditAgreementSection = ({ customer, refetch }: ICreditAgreementSectionProps) => {
  const application = get('latestApplication', customer);

  return (
    <BorderedContainer>
      <CreditSubContainer>
        <ApplicationInfo application={application} seeker />
      </CreditSubContainer>
      <CreditSubContainer>
        <TwoColumnContainer.SecondaryItemTitle>Credit Terms</TwoColumnContainer.SecondaryItemTitle>
        <SignedAgreements customer={customer} />
      </CreditSubContainer>
      <CreditSubContainer>
        <ApplicationAction customer={customer} refetch={refetch} />
      </CreditSubContainer>

    </BorderedContainer>
  );
};