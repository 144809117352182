import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { useLocation } from 'react-router-dom';

import { SEND_VERIFICATION_EMAIL } from '@/graphql/mutations/sendVerificationEmail';
import { H3, BodyText } from '@/components/designSystem/Typography';
import { Button } from '@/components/designSystem/buttons';

const StyledButton = styled(Button)`
  margin-top: 1rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 40rem;
`;

const StyledErrorText = styled(BodyText)`
  margin-top: 1rem;
`;

interface IProps {
  checkUser: () => void,
  customerId?: string | null,
}

export const EmailVerification = ({ checkUser, customerId }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [isRefetched, setIsRefetched] = useState(false);
  const { pathname, search } = useLocation();

  const [sendVerificationEmail, { loading: verifyLoading }] = useMutation(SEND_VERIFICATION_EMAIL);

  const resendVerificationEmail = () => {
    sendVerificationEmail({ variables: { redirectTo: `${window.location.origin}${pathname}${search}`, customerId: customerId } })
      .then(() => {
        showToast({
          title: 'Email Sent',
          description: 'Check your inbox to finish the verification process',
          type: toast.TYPE.SUCCESS,
        });
      });
  };

  const updateUser = () => {
    setLoading(true);
    checkUser();
    setTimeout(() => setLoading(false), 2000);
    setIsRefetched(true);
  };

  return (
    <Container>
      <H3 bold>Email Verification</H3>
      <BodyText>Almost there! But first we need to be sure you are who you say you are.</BodyText>
      <BodyText>Please check your inbox for an email from us. Inside there's a link that will verifiy your account.</BodyText>
      <BodyText>If you have not yet received the verification email, <b>please remember to check your spam/junk folder as well.</b></BodyText>
      <StyledButton wide primary loading={loading} onClick={updateUser}>I've verified my email! Let me continue.</StyledButton>
      <StyledButton wide secondary loading={verifyLoading} onClick={resendVerificationEmail}>Resend verification email</StyledButton>
      {(isRefetched && !loading) && (
        <StyledErrorText color='negative'>
          Email is still not verified. Please be sure to check your spam folder in case you do not see the verification email in your Inbox. Once you verify the email you can come back to this page and continue.
        </StyledErrorText>
      )}
    </Container>
  );
};
