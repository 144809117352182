import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { formatCurrency } from '@/utils/money';
import { formatDateToShortDateString } from '@/utils/date';

import { IAuditHistoryItem } from '@/types/application';

import { Caption, Small, StyledLinkButtonUnderline } from '@/components/designSystem/Typography';
import { SideTitle } from './SideTitle';
import { StyledHistoryItemContainer, StyledHistoryItemCaptions, StyledActivitiesList } from './styled';
import { Flex } from '@/components/designSystem/Layout';

interface IHistoryItemProps {
  status: string,
  creditLimit: string,
  creditTerms: string,
  comment?: string,
  date: string,
}

const HistoryItem = ({ status, creditLimit, creditTerms, comment, date }: IHistoryItemProps) => {
  const [isCommentShown, setCommentShown] = useState(false);

  return (
    <StyledHistoryItemContainer>
      <StyledHistoryItemCaptions>
        <Caption>Status: {status}</Caption>
        <Caption>Credit limit: {formatCurrency(+creditLimit)}</Caption>
        <Caption>Credit terms: {creditTerms}</Caption>
        {comment ? (
          <Caption>Comment: {
            isCommentShown ? comment : <StyledLinkButtonUnderline onClick={() => setCommentShown(true)}>Show Comment</StyledLinkButtonUnderline>
          }
          </Caption>
        ) : false}
      </StyledHistoryItemCaptions>
      <Small color='secondary'>{formatDateToShortDateString(date)}</Small>
    </StyledHistoryItemContainer>
  )};

interface IProps {
  history: IAuditHistoryItem[],
}

export const History = ({
  history
}: IProps) => (
  <Flex direction='column' gap='0.5rem'>
    <SideTitle title='History' />
    <StyledActivitiesList>
      {history.reverse().map((historyItem) => (
        <HistoryItem
          key={uuidv4()}
          status={historyItem.status}
          creditLimit={historyItem.creditLimit}
          creditTerms={historyItem.creditTerms}
          comment={historyItem.providerComment}
          date={historyItem.updatedAt}/>
      ))}
    </StyledActivitiesList>
  </Flex>
);
