import React, { Dispatch, SetStateAction } from 'react';

import { Formik } from 'formik';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { addVendorInitialValues } from './initialValues';
import { addVendorFormValidation } from './validation';
import { AddVendorForm } from './form';
import { IAddVendorFormValues } from './types';
import { ICustomer } from '@/types/customer';
import { useMutation } from '@apollo/react-hooks';
import { UPSERT_MANUAL_VENDOR_REFERRAL } from '@/graphql/mutations/upsertManualVendorReferral';
import { get } from 'lodash/fp';
import { IVendor } from '@/types/vendor';

interface IProps {
  customerData?: ICustomer,
  reference?: IVendor,
  setAddVendorsActive: Dispatch<SetStateAction<boolean>>,
  refetch: () => void;
}

export const AddVendor = ({ customerData, reference, setAddVendorsActive, refetch}: IProps) => {
  const [upsertManualVendorReferral] = useMutation(UPSERT_MANUAL_VENDOR_REFERRAL);
  const customerId = get('id', customerData);
  const providerCompanyID = get('providerCompany.id', customerData);

  const inviteId = reference?.sourceCompany?.inviteId

  const handleSubmit = (data: IAddVendorFormValues) => {
    upsertManualVendorReferral({
      variables: {
        customerId: customerId,
        vendorReference: data,
        blockSend: true,
        forCompanyId: providerCompanyID,
        referralId: inviteId,
      }
    })
      .then(() => {
        showToast({
          title: 'Updated Request Sent!',
          description: 'New Vendor Reference Request have been successfully Sent',
          type: toast.TYPE.SUCCESS,
        });
        setAddVendorsActive(false);
        refetch();
      })
      .catch(() => {
        showToast({
          title: 'Error',
          description: 'Something went wrong',
          type: toast.TYPE.ERROR,
        });
      });
  }

  return (
    <Formik
      initialValues={addVendorInitialValues(reference)}
      validationSchema={addVendorFormValidation}
      onSubmit={handleSubmit}
    >
      <AddVendorForm setAddVendorsActive={setAddVendorsActive} />
    </Formik>)
};