import gql from 'graphql-tag';

export const GET_CREDIT_CUSTOMER_OF = gql`
  query GetCreditCustomerOf {
    currentCompany {
      id
      customerOf {
        id
        providerCompany {
          id
          name
        }
        latestApplication {
          id
          status
          seekerDisplayStatus
          creditLimit
          creditTerms
          dateApproved
          lastUpdatedBy {
            id
            name
          }
        }
      }
    }
  }
`;
