import React from 'react';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { MutationFunctionOptions, useMutation } from '@apollo/react-hooks';
import { UPDATE_COMPANY_SETTINGS } from '@/graphql/mutations/updateCompanySettings';

import getOr from 'lodash/fp/getOr';
import { extractGQLErrorMessage } from '@/utils/extractGQLErrorMessage';

import { ISettings } from '@/types/settings';
import { ILabelValue } from '@/types/field';

import { COMPANY_TYPES } from '@/constants';

import { H4 } from '@/components/designSystem/Typography';
import { Flex } from '@/components/designSystem/Layout';
import { EnableRequiredOptions, IHandleEnableRequiredOptionsChangeArgs } from '@/components/EnableRequiredOptions';

import { StyledEnableRequiredRow, StyledOptionsContainer } from '../styled';
import { StyledMultiSelect } from './styled';

type IAppliesToSettingsKeys =
  'enableAddressFor'
  | 'enableSsnSinNumberFor'
  | 'enableDriverLicenseNoFor'
  | 'enableDriverLicenseStateFor'
  | 'enableDateOfBirthFor'

interface IProps {
  initialSettings: ISettings,
  refetch: () => void,
}

const OWNERSHIP_STAKE_KEYS = {
  enabled: 'enable_ownership_stake',
  required: 'require_ownership_stake',
}

export const AdditionalPersonalInformation = ({ initialSettings, refetch }: IProps) => {
  const requireOwnershipStake: boolean = getOr(true, 'requireOwnershipStake', initialSettings);
  const enableOwnershipStake: boolean = getOr(true, 'enableOwnershipStake', initialSettings);

  const [updateCompanySettings] = useMutation(UPDATE_COMPANY_SETTINGS);

  const handleChangeOwnershipStake = (state: any) => {
    updateCompanySettings({variables: {settings: state}}).
    then(refetch);
  }

  const mapAppliesToValueToOptions = (key: IAppliesToSettingsKeys): ILabelValue[] => {
    const appliesToList = getOr(null, key, initialSettings)?.split(',') || [];

    return appliesToList.map((item: string) => COMPANY_TYPES.find((type) => item === type.value) || { label: item, value: item })
  }

  const items = [
    {
      label: 'Home Address',
      keys: {
        enabled: 'enable_address',
        required: 'require_address',
        appliedTo: 'enable_address_for',
      },
      enabled: getOr(false, 'enableAddress', initialSettings),
      required: getOr(false, 'requireAddress', initialSettings),
      appliesTo: mapAppliesToValueToOptions('enableAddressFor'),
    },
    {
      label: 'SSN/SIN Number',
      keys: {
        enabled: 'enable_ssn_sin_number',
        required: 'require_ssn_sin_number',
        appliedTo: 'enable_ssn_sin_number_for',
      },
      enabled: getOr(false, 'enableSsnSinNumber', initialSettings),
      required: getOr(false, 'requireSsnSinNumber', initialSettings),
      appliesTo: mapAppliesToValueToOptions('enableSsnSinNumberFor'),
    },
    {
      label: "Driver's License Number",
      keys: {
        enabled: 'enable_driver_license_no',
        required: 'require_driver_license_no',
        appliedTo: 'enable_driver_license_no_for',
      },
      enabled: getOr(false, 'enableDriverLicenseNo', initialSettings),
      required: getOr(false, 'requireDriverLicenseNo', initialSettings),
      appliesTo: mapAppliesToValueToOptions('enableDriverLicenseNoFor'),
    },
    {
      label: "Driver's License State/Province",
      keys: {
        enabled: 'enable_driver_license_state',
        required: 'require_driver_license_state',
        appliedTo: 'enable_driver_license_state_for',
      },
      enabled: getOr(false, 'enableDriverLicenseState', initialSettings),
      required: getOr(false, 'requireDriverLicenseState', initialSettings),
      appliesTo: mapAppliesToValueToOptions('enableDriverLicenseStateFor'),
    },
    {
      label: 'Date of Birth',
      keys: {
        enabled: 'enable_date_of_birth',
        required: 'require_date_of_birth',
        appliedTo: 'enable_date_of_birth_for',
      },
      enabled: getOr(false, 'enableDateOfBirth', initialSettings),
      required: getOr(false, 'requireDateOfBirth', initialSettings),
      appliesTo: mapAppliesToValueToOptions('enableDateOfBirthFor'),
    }
  ];

  const handleUpdateCompanySettings = (variables: MutationFunctionOptions) => updateCompanySettings(variables)
    .then(() => {
      showToast({
        title: 'Success!',
        description: 'Company settings Updated Successfully.',
        type: toast.TYPE.SUCCESS,
      });
      refetch();
    })
    .catch((error) => {
      showToast({
        title: 'Error',
        description: extractGQLErrorMessage(error),
        type: toast.TYPE.ERROR,
      });
    });

  const handleEnabledRequiredChange = (state: any) => handleUpdateCompanySettings({variables: {settings: state}})

  const handleAppliedToChange = (options: ILabelValue[], key: string) => {
    const settings = {
      [key]: options.map((option) => option.value).join(),
    };

    return handleUpdateCompanySettings({ variables: { settings } });
  }

  return (
    <StyledOptionsContainer disableDivider>
      <Flex justify='space-between' align='center'>
        <H4 bold>Additional Personal Information for Owners</H4>
      </Flex>
      <StyledEnableRequiredRow key='ownership_stake' first={true}>
        <EnableRequiredOptions
          id='ownership-stake'
          label='Ownership Stake'
          keys={OWNERSHIP_STAKE_KEYS}
          enabled={enableOwnershipStake}
          required={requireOwnershipStake}
          handleChange={handleChangeOwnershipStake}
        />
      </StyledEnableRequiredRow>
      {items.map((item) => (
        <StyledEnableRequiredRow key={item.label} first={false}>
          <EnableRequiredOptions
            id={item.label}
            label={item.label}
            keys={item.keys}
            enabled={item.enabled}
            required={item.required}
            handleChange={handleEnabledRequiredChange}
          />
          <StyledMultiSelect
            options={COMPANY_TYPES}
            value={item.appliesTo}
            onChange={(options: ILabelValue[]) => handleAppliedToChange(options, item.keys.appliedTo)}
            labelledBy='select'
            disableSearch={true}
            ClearSelectedIcon={null}
            overrideStrings={{
              selectSomeItems: 'Select Business Type(s)',
              selectAll: 'Select All Business Types',
              allItemsAreSelected: 'All Business Types Selected'
            }}
            disabled={!item.enabled}
          />
        </StyledEnableRequiredRow>
      ))}
    </StyledOptionsContainer>
  )
};
