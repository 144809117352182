import React from 'react';

import { get, getOr } from 'lodash/fp';

import { ICustomer } from '@/types/customer';
import { IAgreement } from '@/types/agreement';
import { IProviderSettings } from '@/types/providerSettings';
import { ICustomFieldAnswerFile } from '@/types/customField';
import { IBankruptcyCase } from '@/types/bankruptcyCase';
import { IDocument } from '@/components/Document';
import { InformationSection } from './InformationSection';
import { ContactsSection } from './ContactsSection';
import { SupportingInfoSection } from './SupportingInfoSection/index';
import { Documents } from './Documents';
import { InternalDocuments } from './InternalDocuments';
import { StyledTabPanelContainer } from '../../styled';
import { CREDIT_APPLICANT_STATUSES, CUSTOM_FIELD_TYPES } from '@/constants';
import { BusinessVerificationSection } from './BusinessVerificationSection';
import { PROVIDER_UPDATE_COMPANY } from '@/graphql/mutations/providerUpdateCompany';
import { useMutation } from '@apollo/react-hooks';
import { LoadingSpinner } from '@/components/LoadingSpinner';

export interface IBankruptciesData {
  count: number,
  cases: IBankruptcyCase[],
}

interface IProps {
  customerData: ICustomer,
  bankruptcies: IBankruptciesData,
  refetchCustomerData: () => void,
  canSeeEditControl: boolean,
  providerSettings: IProviderSettings
}

export const CompanyInfo = ({ customerData, bankruptcies, refetchCustomerData, canSeeEditControl, providerSettings }: IProps) => {

  const [providerUpdateCompany, { loading: updateCompanyLoading }] = useMutation(PROVIDER_UPDATE_COMPANY);

  const status = getOr('', 'latestApplication.providerDisplayStatus', customerData);
  const agreements: IAgreement[] = get('latestApplication.agreements', customerData);
  const applicationDocuments: any[] = get('latestApplication.documents', customerData);
  const einFederalTaxNumber = get('latestApplication.einFederalTaxNumber', customerData);
  const customFieldAnswers = get('customFieldAnswers', customerData);
  const customDocuments = customFieldAnswers?.filter((customFieldAnswer) => customFieldAnswer.file) as ICustomFieldAnswerFile[] || [];

  const customFieldTextAnswers = customFieldAnswers?.filter(
    (customFieldAnswer) => (customFieldAnswer.customField.type === CUSTOM_FIELD_TYPES.TEXT && customFieldAnswer.text)
    || (customFieldAnswer.customField.type === CUSTOM_FIELD_TYPES.TOGGLE && customFieldAnswer.customField.yesToggle.type === CUSTOM_FIELD_TYPES.TEXT)
    || (customFieldAnswer.customField.type === CUSTOM_FIELD_TYPES.DROPDOWN && customFieldAnswer.text)
    || (customFieldAnswer.customField.type === CUSTOM_FIELD_TYPES.CURRENCY && customFieldAnswer.currency)
    || (customFieldAnswer.customField.type === CUSTOM_FIELD_TYPES.MULTIPLE_CHOICE && customFieldAnswer.selections.length)
    || (
      customFieldAnswer.customField.type === CUSTOM_FIELD_TYPES.TOGGLE
      && customFieldAnswer.customField.yesToggle.type === CUSTOM_FIELD_TYPES.FILE_UPLOAD
      && !customFieldAnswer.file)
  ) || [];

  const applicationId = get('latestApplication.id', customerData);
  const documents: IDocument[] = applicationDocuments
    .map((appDoc) => ({title: appDoc.originalFilename, content: appDoc.originalFilename, file: appDoc}));

  if (updateCompanyLoading) {
    return <LoadingSpinner />
  }

  return (
    <StyledTabPanelContainer>
      <InformationSection
        canSeeEditControl={canSeeEditControl}
        customerData={customerData}
        providerUpdateCompany={providerUpdateCompany}
        refetch={refetchCustomerData}
        providerSettings={providerSettings}
      />
      {(![CREDIT_APPLICANT_STATUSES.INVITED, CREDIT_APPLICANT_STATUSES.IN_PROGRESS].includes(status)) ? (
        <BusinessVerificationSection
          customerData={customerData}
          bankruptcies={bankruptcies}
        />
      ) : false }
      <ContactsSection canSeeEditControl={canSeeEditControl} customerData={customerData} refetch={refetchCustomerData} />
      {(agreements.length || customDocuments.length) ? (
        <Documents agreements={agreements} customDocuments={customDocuments} />
      ) : null}

      <SupportingInfoSection
        canSeeEditControl={canSeeEditControl}
        customer={customerData}
        providerUpdateCompany={providerUpdateCompany}
        refetch={refetchCustomerData}
      />

      <InternalDocuments documents={documents} applicationId={applicationId} refetch={refetchCustomerData} />
    </StyledTabPanelContainer>
  )
};
