import { useMemo } from 'react';

import { getOr } from 'lodash/fp';

import { ICustomer } from '@/types/customer';
import { IPendingCustomerInvite } from '@/types/invite';

import { CUSTOM_FIELD_TYPES, VR_STATUS } from '@/constants';
import { IGetDashboardOverviewData } from '@/types/querries';
import { ICustomFieldAnswer } from '@/types/customField';
import { ISeekerCompany } from '@/types/seekerCompany';

export const useCustomersData = (data?: IGetDashboardOverviewData) => {
  const customers: ICustomer[] = getOr([], 'customizedCurrentCompany.customers', data);
  const customerInvites: IPendingCustomerInvite[] = getOr([], 'customizedCurrentCompany.pendingCustomerInvites', data);
  // const requiredReferencesNumber = getOr(0, 'customizedCurrentCompany.numRequiredReferences', data);

  // const generateVRStatus = (seekerCompany: ISeekerCompany) => {
  //   const receivedReferencesNumber = getOr(0, 'receivedReferencesNumber', seekerCompany);
  //   const pendingVendorInvitesNumber = getOr(0, 'pendingVendorInvitesNumber', seekerCompany);
  //   const allReferences = receivedReferencesNumber + pendingVendorInvitesNumber;

  //   if (allReferences === 0) {
  //     return VR_STATUS.FAILURE;
  //   }
  //   if (receivedReferencesNumber >= requiredReferencesNumber) {
  //     return VR_STATUS.SUCCESS;
  //   }
  //   return VR_STATUS.PENDING;
  // };

  const generateCustomersData = () => [
    ...customers.map((customer) => {

      const additionalAnswers = customer.customFieldAnswers
        .filter((customFieldAnswer) => customFieldAnswer.customField.type === CUSTOM_FIELD_TYPES.ADDITIONAL_QUESTION);

      return {
        id: customer.id,
        company: customer.seekerCompany.name,
        status: customer.latestApplication?.providerDisplayStatus,
        creditLimit: customer.latestApplication?.creditLimit,
        creditTerms: customer.latestApplication?.creditTerms,
        lastUpdatedBy: customer.latestApplication?.lastUpdatedBy.name,
        lastUpdatedOn: customer.latestApplication?.updatedAt,
        dateApproved: customer.latestApplication?.dateApproved,
        ...additionalAnswers.reduce<Partial<ICustomFieldAnswer>>((acc, additionalAnswer) => ({ ...acc, [additionalAnswer.customField.id]: additionalAnswer.text}), {})
      }}),
    ...customerInvites.map((customerInvite) => ({
      id: `referral/${customerInvite.referralCode}`,
      company: customerInvite.toCompanyName,
      status: customerInvite.sent ? 'Sent' : 'Created',
      lastUpdatedBy: customerInvite.from.name,
      vendorReference: VR_STATUS.FAILURE,
      bankReference: VR_STATUS.PENDING,
    }))
  ];

  const customersData = useMemo(() => generateCustomersData(), [generateCustomersData]);

  return customersData;
};
