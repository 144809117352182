import React from 'react';
import { ReactComponent as Clipboard } from '@/assets/navBarIcons/clipboard.svg';
import { ReactComponent as Exit } from '@/assets/navBarIcons/exit.svg';
import { ReactComponent as Gear } from '@/assets/navBarIcons/gear.svg';
import { ReactComponent as Lifesaver } from '@/assets/navBarIcons/lifesaver.svg';
import { ReactComponent as Sliders } from '@/assets/navBarIcons/sliders.svg';
import { ReactComponent as Thumbsup } from '@/assets/navBarIcons/thumbsup.svg';
import { ReactComponent as People } from '@/assets/navBarIcons/twopeople.svg';
import { NAV_ITEMS } from '../../../constants';

interface IProps {
  title: string,
}

export const NavBarIcon = ({ title }: IProps) => {
  switch (title) {
  case NAV_ITEMS.CUSTOMERS:
    return <People />;
  case NAV_ITEMS.APPLICATIONS:
    return <Clipboard />;
  case NAV_ITEMS.REFERENCES:
    return <Thumbsup />;
  case NAV_ITEMS.TEMPLATE:
    return <Gear />;
  case NAV_ITEMS.SETTINGS:
    return <Sliders />;
  //case NAV_ITEMS.HELP:
  //  return <Lifesaver />;
  case NAV_ITEMS.LOGOUT:
    return <Exit />;
  default:
    return null;
  }
};
