import React, { Dispatch, useContext, useEffect, useRef } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro-spa';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_FINICITY_CUSTOMER } from '@/graphql/queries/getFinicityCustomer';
import { UPSERT_MANUAL_INSTITUTIONS } from '@/graphql/mutations/destructiveUpsertManualInstitutions';

import { Formik } from 'formik';

import { get, getOr, noop } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { ISettings } from '@/types/settings';

import { VisitorContext } from '@/providers/VisitorContext';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { EmailVerification } from '@/views/onboard/CreditApplicantOnboard/EmailVerification';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { IOnboardValuesAction } from '../../types';
import { FinancialHealthStepView } from './views';
import { IFinancialHealthValues } from './types';
import { OnboardValuesContext } from '../../context';
import { financialHealthStepValidation } from './validation';
import { ManualBankView } from './views/ManualBankView';
import { UPDATE_FINANCIAL_HEALTH } from '../../reducer';
import { mapOnboardValuesToUpsertManualInstitutionsVariables } from './functions';
import { useEmailVerified } from './useEmailVerified';

interface IProps {
  dispatch: Dispatch<IOnboardValuesAction>,
  companySettings: ISettings,
  automaticBankVerificationEnabled: boolean,
  requireBankInfo: boolean,
}

export const FinancialHealthStep = ({ dispatch, companySettings, automaticBankVerificationEnabled, requireBankInfo }: IProps) => {
  const visitorData: ExtendedGetResult | null = useContext(VisitorContext);
  const history = useHistory();
  const { search } = useLocation();
  const firstUpdate = useRef(true);

  const onboardValues = useContext(OnboardValuesContext);

  const {
    data: getFinicityCustomerData, refetch: refetchFinicityCustomerData, loading: getFinicityCustomerLoading
  } = useQuery(GET_FINICITY_CUSTOMER);

  const {
    emailVerifiedData,
    isEmailVerified,
    refetchEmailVerification
  } = useEmailVerified(onboardValues, dispatch)

  const [upsertManualInstitutions] = useMutation(UPSERT_MANUAL_INSTITUTIONS);

  const currentUserEmail = get('basicInfo.email', onboardValues);

  const finicityAccounts = getOr([], 'getFinicityCustomer.finicityAccounts', getFinicityCustomerData);
  const isFinicityConnected = finicityAccounts.length;

  const requestBankStatements = get('requestBankStatements', companySettings);

  const submitStep = () => {
    if (!onboardValues?.financialHealth.manualBanks) {
      return noop;
    }

    const upsertManualInstitutionsVariables =
      mapOnboardValuesToUpsertManualInstitutionsVariables(onboardValues.financialHealth.manualBanks);

    return upsertManualInstitutions({
      variables: {
        customerId: onboardValues.customerId,
        manualInstitutions: upsertManualInstitutionsVariables,
        noBankInfo: onboardValues.financialHealth.noBankInfo ?? false,
        visitorData,
      }
    })
      .then(() => {
        history.push({ pathname: `${onboardValues.basePath}/vendors`, search });
      })
      .catch(() => {
        showToast({
          title: 'Error',
          description: 'Something went wrong',
          type: toast.TYPE.ERROR,
        });
      });
  };

  useEffect(() => {
    // To skip function call after initial render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    submitStep();
  }, [onboardValues?.financialHealth]);

  const dispatchManualBanksValues = (values: IFinancialHealthValues) => {
    dispatch({ type: UPDATE_FINANCIAL_HEALTH, payload: values });
  }

  if (!onboardValues || getFinicityCustomerLoading || !(emailVerifiedData || onboardValues?.emailVerified)) {
    return <LoadingSpinner />
  }

  if (isEmailVerified) {
    return (
      <Formik
        initialValues={onboardValues.financialHealth}
        validationSchema={financialHealthStepValidation(currentUserEmail, requireBankInfo)}
        onSubmit={(values) => handleSubmitWrapper(values, dispatchManualBanksValues)}
      >
        <Switch>
          <Route exact path={`${onboardValues.basePath}/bank`}
            render={() => (
              <FinancialHealthStepView
                isFinicityConnected={isFinicityConnected}
                requireBankInfo={requireBankInfo}
                refetchFinicityCustomerData={refetchFinicityCustomerData}
                automaticBankVerificationEnabled={automaticBankVerificationEnabled}
                basePath={onboardValues.basePath}
              />
            )}
          />
          <Route path={`${onboardValues.basePath}/bank/manual`}
            render={() => (
              <ManualBankView
                requireBankInfo={requireBankInfo}
                requestBankStatements={requestBankStatements}
                automaticBankVerificationEnabled={automaticBankVerificationEnabled}
              />
            )}
          />
        </Switch>
      </Formik>
    )
  }

  return (
    <EmailVerification checkUser={refetchEmailVerification} customerId={onboardValues.customerId} />
  )
};
