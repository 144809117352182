import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { LoginContainer } from '@/containers/LoginContainer';

import { LoginPage } from '@/views/login/LoginPage';
import { ForgotPasswordPage } from '@/views/login/ForgotPasswordPage';

export const LoginApp = () => (
  <LoginContainer>
    <Switch>
      <Route exact path='/login/forgot' component={ForgotPasswordPage} />
      <Route path='/login' component={LoginPage} />
    </Switch>
  </LoginContainer>
);