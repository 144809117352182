import { Dispatch } from 'react';

import { get, getOr } from 'lodash/fp';

import { ICustomField } from '@/types/customField';
import { IReferralData } from '@/types/referralData';

import { CUSTOM_FIELD_TYPES } from '@/constants';

import { OrientationStep } from './steps/OrientationStep';
import { BasicInfoStep } from './steps/BasicInfoStep';
import { CompanyProfileStep } from './steps/CompanyProfileStep';
import { CompanyContactsStep } from './steps/CompanyContactsStep';
import { FinancialHealthStep } from './steps/FinancialHealthStep';
import { VendorsStep } from './steps/VendorsStep';
import { OtherInfoStep } from './steps/OtherInfoStep';
import { AgreementStep } from './steps/AgreementStep';
import { IOnboardValuesAction } from './types';

interface IGetStepsArgs {
  refData: IReferralData,
  dispatch: Dispatch<IOnboardValuesAction>,
  referralCode: string | null,
}

export const buildCurrentSettings = (refData: IReferralData) => {
  const companySettings = getOr({}, 'getCustomerAsSeeker.providerCompany.settings', refData);
  const refSettings = getOr({}, 'getReferralData', refData);
  const customerSettings = getOr({}, 'getCustomerAsSeeker.settings', refData);
  return Object.assign(
    Object.assign(companySettings, { autoBav: companySettings.autoBavDefault }),
    refSettings,
    customerSettings
  );
}

export const getSteps = ({
  refData,
  dispatch,
  referralCode
}: IGetStepsArgs) => {
  const providerCompany = getOr(null, 'getCompanyById', refData);
  const providerCompanyId = get('id', providerCompany);
  const providerCompanyName = get('name', providerCompany);
  const creditTermsOptions = getOr([], 'creditTerms', providerCompany);

  const companySettings = get('settings', providerCompany);
  const showCreditTerms = getOr(false, 'attachCreditOptions', companySettings);
  const requireEinFederalTaxNumber = getOr(false, 'requireEinFederalTaxNumber', companySettings);

  const customFields = getOr([], 'customFields', providerCompany);
  const additionalQuestions: ICustomField[] = customFields.filter(customField => customField.type === CUSTOM_FIELD_TYPES.ADDITIONAL_QUESTION);
  const customContactFields: ICustomField[] = customFields.filter(customField => customField.type === CUSTOM_FIELD_TYPES.CONTACT);
  const customFieldsForOtherInfoStep: ICustomField[] = customFields.filter(
    customField => (customField.type !== CUSTOM_FIELD_TYPES.ADDITIONAL_QUESTION) && (customField.type !== CUSTOM_FIELD_TYPES.CONTACT));

  const currentCompany = get('currentCompany', refData);
  const currentUser = get('currentUser', refData);
  const automaticBankVerificationEnabled = getOr(false, 'getCustomerAsSeeker.settings.autoBav', refData);
  // Some weird way to get 'requireBankInfo' property.
  // Consider API refactoring.
  const requireBankInfo = buildCurrentSettings(refData).requireBankInfo;

  return [
    {
      path: 'orientation',
      Component: OrientationStep,
      stepProps: {
        dispatch,
        companyName: providerCompanyName,
        providerCompanyId: providerCompanyId,
      }
    },
    {
      path: 'basicInfo',
      Component: BasicInfoStep,
      stepProps: {
        dispatch,
      }
    },
    {
      path: 'companyProfile',
      Component: CompanyProfileStep,
      stepProps: {
        dispatch,
        additionalQuestions,
        companySettings,
      }
    },
    {
      path: 'stakeholder',
      Component: CompanyContactsStep,
      stepProps: {
        dispatch,
        companySettings,
        customContactFields,
        currentCompany,
        currentUser,
        providerCompanyId,
        referralCode
      }
    },
    {
      path: 'bank',
      Component: FinancialHealthStep,
      stepProps: {
        dispatch,
        companySettings,
        automaticBankVerificationEnabled,
        requireBankInfo,
      }
    },
    {
      path: 'vendors',
      Component: VendorsStep,
      stepProps: {
        dispatch,
        providerCompanyId,
      }
    },
    {
      path: 'others',
      Component: OtherInfoStep,
      stepProps: {
        dispatch,
        companySettings,
        customFields: customFieldsForOtherInfoStep,
        showCreditTerms,
        creditTermsOptions,
        requireEinFederalTaxNumber
      }
    },
    {
      path: 'agreement',
      Component: AgreementStep,
      stepProps: {
        providerCompany
      }
    },
  ]
}
