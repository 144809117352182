import React, { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { SEND_MANUAL_CUSTOMER_FOLLOW_UP } from '@/graphql/mutations/sendManualFollowUp';

import { get, getOr, sortBy, flow, reverse } from 'lodash/fp';

import { ICustomer } from '@/types/customer';
import { IActivity } from '@/types/activity';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { ActivityItem } from './ActivityItem';
import { SideTitle } from '../SideTitle';
import { StyledEmailActivityFeedContainer, StyledActivitiesList, StyledShowAllButton, StyledSideButton } from '../styled';

interface IProps {
  customerData: ICustomer,
  customerId: string,
  refetchCustomerData: () => void,
  canSeeEditControl: boolean,
}

export const EmailActivityFeed = ({
  customerData, customerId, refetchCustomerData, canSeeEditControl
}: IProps) => {
  const activities: IActivity[] = getOr([], 'activityFeed', customerData);
  const onboardComplete = getOr(false, 'onboardComplete', customerData);
  const isManualCustomer = getOr(false, 'isManualCustomer', customerData);
  const sortedActivities = flow(
    sortBy<IActivity>(({ occuredAt }) => new Date(occuredAt)),
    reverse
  )(activities);

  const [showAll, setShowAll] = useState(false);
  const trucatedLength = 3;

  const [sendFollowup, { loading }] = useMutation(
    SEND_MANUAL_CUSTOMER_FOLLOW_UP,
    {
      variables: { customerId },
      onCompleted: () => {
        showToast({
          title: 'Follow Up Sending',
          description: `Your follow up has been sent to ${get('seekerCompany.name', customerData)}. You will see it in your activty feed shortly.`,
          type: toast.TYPE.SUCCESS,
        });
        refetchCustomerData();
      },
    },
  );

  return (
    <StyledEmailActivityFeedContainer>
      <SideTitle title='Email Activity Feed' />
      <StyledActivitiesList>
        {showAll
          ? (sortedActivities
            .map((activity) => <ActivityItem key={activity.occuredAt} occuredAt={activity.occuredAt} description={activity.description} />))
          : (sortedActivities
            .slice(0, 3)
            .map((activity) => <ActivityItem key={activity.occuredAt}occuredAt={activity.occuredAt} description={activity.description} />))
        }
      </StyledActivitiesList>
      {
        activities.length > trucatedLength ? (
          <StyledShowAllButton onClick={() => setShowAll(!showAll)}>
            Show {showAll ? 'less' : 'more'}
          </StyledShowAllButton>
        ) : null
      }
      {!onboardComplete && canSeeEditControl && !isManualCustomer && (
        <StyledSideButton
          primary
          loading={loading}
          onClick={() => sendFollowup()}
        >
          Send Reminder
        </StyledSideButton>
      )
      }
    </StyledEmailActivityFeedContainer>
  );
};
