import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useFormikContext } from 'formik';
import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { get, getOr } from 'lodash/fp';

import { IReferralData } from '@/types/referralData';

import { CheckboxField } from '@/components/designSystem/Form/CheckboxField';
import { H2, BodyText, StyledExternalLink } from '@/components/designSystem/Typography';
import { ManualCustomerSignAgreementButton } from '@/views/onboard/ManualCustomerSignAgreementButton';
import { FlexColumn, FlexColumnCenter } from '@/components/designSystem/Layout';
import { Row } from '@/components/designSystem/Form/styled';
import { TextField } from '@/components/designSystem/Form/TextField';

const Container = styled(FlexColumn)`
  max-width: 600px;
  width: 50vw;
  height: 100%;
`;

const TitleContainer = styled(FlexColumnCenter)`
  margin-bottom: 1.5rem;

  & > :first-child {
    margin-bottom: 0.5rem;
  }
`;

const AgreementContainer = styled.div`
  border: 1px solid rgba(22, 22, 22, 0.16);
  box-sizing: border-box;
  /* Subtle shadow */
  box-shadow: ${({ theme }) => theme.color.shadow.shadowSmall};
  border-radius: 12px;
  height: 700px;
  overflow-y: scroll;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  white-space: pre-line;
`;

interface IAgreementProps {
  agreementText: string,
}

const Agreement = ({ agreementText }: IAgreementProps) => (
  <AgreementContainer>
    <BodyText>{agreementText}</BodyText>
  </AgreementContainer>
);

const FieldContainer = styled(FlexColumnCenter)`
  & > * {
    margin: 1rem;
  }
`;

interface IButton {
  children: string,
  disabled: boolean,
}

const StyledSignButton = styled(ManualCustomerSignAgreementButton).attrs({ primary: true })<IButton>`
  margin-top: 1.5rem;
  width: 100%;
`;

interface IAgreementStepProps {
  agreementText: string,
  providerCompanyName: string,
  customerId: string,
  userId: string,
  seekerCompanyName: string,
}

const AgreementSignatureStep = ({ agreementText, providerCompanyName, customerId, userId, seekerCompanyName }: IAgreementStepProps) => {
  const {
    values, setFieldValue, setTouched, handleSubmit, validateForm
  } = useFormikContext();

  useEffect(() => {
    validateForm();
  }, []);

  const authorizedToSignCheck = getOr(false, 'authorizedToSignCheck', values);
  const agreeCheck = getOr(false, 'agreeCheck', values);
  const name = getOr(null, 'name', values);
  const email = getOr(null, 'email', values);
  const validEmail = Yup.string().email().isValidSync(email);
  const signed = getOr(false, 'signed', values);
  const canSign = authorizedToSignCheck && agreeCheck && name && email && validEmail;

  // TODO Fix any
  useEffect(() => {
    if (signed) {
      handleSubmit(values as any);
    }
  }, [signed]);

  const onAgreementSigned = () => {
    setFieldValue('signed', true);
    setTouched({ signed: true });
    handleSubmit(values as any);
  };

  return (
    <Container>
      <TitleContainer>
        <H2 bold>Credit Agreement</H2>
        <BodyText>
          {providerCompanyName} would like an authorized representative of {seekerCompanyName} to sign the Terms and Conditions on this credit application.
        </BodyText>
      </TitleContainer>
      <Agreement agreementText={agreementText} />
      <FieldContainer>
        <CheckboxField name='authorizedToSignCheck'>
          <BodyText>
          I acknowledge my electronic signature is legally binding and I am authorized to apply for credit and enter into this agreement on behalf of my company.
          I hereby authorize the bank, vendors and other sources named herein to release the data and information requested for the purpose of obtaining and/or
          reviewing my company's credit and/or for other services offered by Nectarine Credit.
          I agree to the Terms and Conditions set forth in this credit agreement.
          </BodyText>
        </CheckboxField>
        <CheckboxField name='agreeCheck'>
          <BodyText>
            {"I agree to Nectarine Credit's "}
            <StyledExternalLink href='https://www.nectarinecredit.com/terms' target='_blank'>Terms and Conditions</StyledExternalLink>
            {" and "}
            <StyledExternalLink href='https://www.nectarinecredit.com/privacy' target='_blank'>Privacy Policy</StyledExternalLink>
          </BodyText>
        </CheckboxField>
      </FieldContainer>
      <Row>
        <TextField required type='text' name='name' label='Your Full Name' placeholder='Full Name' />
        <TextField required type='email' name='email' label='Email' placeholder='you@company.com'/>
      </Row>
      <StyledSignButton providerCompanyName={providerCompanyName} customerId={customerId} userId={userId} name={name} email={email} onSuccess={onAgreementSigned} disabled={!canSign}>
        Sign Agreement
      </StyledSignButton>
    </Container>
  );
};

const agreementStepFields = (referralData: IReferralData) => {
  const signedStatus = get('getCustomerAsSeeker.latestApplication.activeAgreement.status', referralData);
  const signed = signedStatus === 'signed';

  const initialFields = {
    authorizedToSignCheck: signed,
    agreeCheck: signed,
    name: '',
    email: '',
    signed,
  };
  return initialFields;
};

const agreementStepValidation = Yup.object().shape({
  authorizedToSignCheck: Yup.boolean(),
  agreeCheck: Yup.boolean(),
  name: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  email: Yup.string().email(DEFAULT_ERROR_MESSAGES.EMAIL).trim().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  signed: Yup.boolean().oneOf([true], 'Contract must be signed, before submit.').required(DEFAULT_ERROR_MESSAGES.REQUIRED),
});

export {
  AgreementSignatureStep,
  agreementStepFields,
  agreementStepValidation,
};
