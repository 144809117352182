import React, { useContext } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { UPDATE_CREDIT_TERMS } from '@/graphql/mutations/updateCreditTermOptions';

import { get, getOr } from 'lodash/fp';
import { extractGQLErrorMessage } from '@/utils/extractGQLErrorMessage';

import { CurrentCompanyContext } from '@/providers/CurrentCompany';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { Button } from '@/components/designSystem/buttons';
import { Row } from '@/components/designSystem/Form/styled';
import { TextField } from '@/components/designSystem/Form/TextField';

import { ReactComponent as Plus } from '@/assets/plus.svg';

import { Section } from './Section';
import { CreditTermFields } from './CreditTermFields';
import { RowWrapper } from './styled';
import { LoadingSpinner } from '@/components/LoadingSpinner';

const CreditLimit = () => (
  // This TextField name is fake. We need it for formik fields.
  // If you need to enable this text field at some point, you will need to fix name
  <TextField type='text' name='creditLimit' disabled label='Credit Limit' placeholder='$23,456' />
);

export const CreditTermsSection = () => {
  const currentCompanyData = useContext(CurrentCompanyContext);

  if (!currentCompanyData) {
    return <LoadingSpinner />
  }

  const refetchCurrentCompany = get('refetchCurrentCompany', currentCompanyData);

  const variables = {
    settings: {
      attach_credit_options: true,
    },
  };

  const [updateCreditTerms, { loading }] = useMutation(UPDATE_CREDIT_TERMS);

  const onClick = () => updateCreditTerms({ variables })
    .then(refetchCurrentCompany)
    .catch((error) => {
      showToast({
        title: 'Error',
        description: extractGQLErrorMessage(error),
        type: toast.TYPE.ERROR,
      });
    });

  const attachCreditOptions = getOr(false, 'currentCompany.settings.attachCreditOptions', currentCompanyData);
  const creditTermsOptions = getOr([], 'currentCompany.creditTerms', currentCompanyData);

  return (
    <Section
      title='Credit Terms And Conditions'
      description='Customers can specify their prefered credit terms and conditions.'
    >
      {attachCreditOptions ? (
        <Row>
          <CreditLimit />
          <CreditTermFields
            creditTermsOptions={creditTermsOptions}
            refetch={refetchCurrentCompany}
          />
        </Row>
      ) : (
        <Row>
          <CreditLimit />
          <RowWrapper>
            <Button fullHeight primary LeftIcon={Plus} loading={loading} onClick={onClick} wide>Add Credit Terms</Button>
          </RowWrapper>
        </Row>
      )}
    </Section>
  );
};
